import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../FirebaseConfig';
import { useAuth } from '../contexts/AuthContext';
import Swal from 'sweetalert2';
import DefaultServicePhoto from '../assets/fulllogo.png';
import BackgroundImage from '../assets/Wall.jpg';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css'; // Import the default styles for react-datepicker


export default function RequestService() {
  const { state } = useLocation();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const phoneRef = useRef();
  const wphoneRef = useRef();
  const nameRef = useRef();
  const locationRef = useRef();
  const dateRef = useRef();
  const [phoneError, setPhoneError] = useState(false);
  const [wphoneError, setWphoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [registeredDate, setRegisteredDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);


  
  useEffect(() => {
    const now = new Date();
    const formatRegisteredDate = now.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
    setRegisteredDate(formatRegisteredDate);
  }, []);

  const formatDate = (date) => {
    const options = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };

  // const handleDateChange = (e) => {
  //   const selectedDate = new Date(e);
  //   const currentDate = new Date();

  //   if (selectedDate < currentDate) {
  //     setDateError(true);
  //   } else {
  //     setDateError(false);
  //     setServiceDetails((prevDetails) => ({
  //       ...prevDetails,
  //       serviceDate: selectedDate,
  //     }));
  //   }
  // };

  const validatePhone = () => {
    if (!phoneRef.current.value) {
      phoneRef.current.setCustomValidity('Please enter a phone number.');
      setPhoneError(true)
    } else if (!phoneRef.current.value.match(/^\+254\d{9}$/)){
      phoneRef.current.setCustomValidity('Phone number should start with +254.');
      setPhoneError(true)
    } else {
      phoneRef.current.setCustomValidity('');
      setPhoneError(false)
    }
    phoneRef.current.reportValidity();
  }

  // const validatePhone = () => {
  //   if (!phoneRef.current.value.match(/^\d{10}$/)) {
  //     phoneRef.current.setCustomValidity("Please enter a valid phone number.");
  //     setPhoneError(true);
  //   } else {
  //     phoneRef.current.setCustomValidity("");
  //     setPhoneError(false);
  //   }
  //   phoneRef.current.reportValidity();
  // };

  const validateWphone = () => {
    if (!phoneRef.current.value) {
      phoneRef.current.setCustomValidity('Please enter a phone number.');
      setWphoneError(true)
    } else if (!phoneRef.current.value.match(/^\+254\d{9}$/)){
      phoneRef.current.setCustomValidity('Phone number should start with +254.');
      setWphoneError(true)
    } else {
      phoneRef.current.setCustomValidity('');
      setWphoneError(false)
    }
    phoneRef.current.reportValidity();
  }
  // const validateWphone = () => {
  //   if (!wphoneRef.current.value.match(/^\d{10}$/)) {
  //     wphoneRef.current.setCustomValidity("Please enter a valid phone number.");
  //     setWphoneError(true);
  //   } else {
  //     wphoneRef.current.setCustomValidity("");
  //     setWphoneError(false);
  //   }
  //   wphoneRef.current.reportValidity();
  // };

  const validateName = () => {
    if (!nameRef.current.value) {
      nameRef.current.setCustomValidity("Please enter your name.");
      setNameError(true);
    }else if(!nameRef.current.value.match(/^[a-zA-Z\s]*$/)){
      nameRef.current.setCustomValidity('Please do not include numbers or special characters.');
      setNameError(true);     
    }else {
      nameRef.current.setCustomValidity("");
      setNameError(false);
    }
    nameRef.current.reportValidity();
  };

  const validateLocation = () => {
    if (!locationRef.current.value) {
      locationRef.current.setCustomValidity("Please enter your location.");
      setLocationError(true);
    } else {
      locationRef.current.setCustomValidity("");
      setLocationError(false);
    }
    locationRef.current.reportValidity();
  };

  
  // Validate date input, it should not be in the past
  const validateDate = () => {
    const selectedDate = new Date(dateRef.current.value);
    const currentDate = new Date();
  
    if (!dateRef.current.value) {
      dateRef.current.setCustomValidity("Please select a date.");
      setDateError(true);
    } else if (selectedDate < currentDate) {
      dateRef.current.setCustomValidity("The selected date cannot be in the past.");
      setDateError(true);
    } else {
      dateRef.current.setCustomValidity("");
      setDateError(false);
    }
    dateRef.current.reportValidity();
  };
  

    


  const provider = state?.provider || JSON.parse(localStorage.getItem('provider'));
  const locationData = state?.locationData;

  const [userDetails, setUserDetails] = useState({});
  const [serviceDetails, setServiceDetails] = useState({
    serviceLocation: locationData ? locationData.street : '',
    clientName: '',
    clientPhone: '',
    clientWhatsAppPhone: '',
    serviceDate: '',
    serviceDescription: '',
    image: '',
  });

  useEffect(() => {
    if (provider) {
      localStorage.setItem('provider', JSON.stringify(provider));
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Provider Error',
        text: 'Provider is undefined. Redirecting to service selection...',
      }).then(() => navigate('/client-home')); 
    }
  }, [provider, navigate]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userId = currentUser.uid;
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails(userData);

          if (!locationData) {
            const locationDocRef = doc(db, 'users', userId, 'Locations', userId);
            const locationDoc = await getDoc(locationDocRef);
            if (locationDoc.exists()) {
              const locationData = locationDoc.data();
              setServiceDetails((prev) => ({
                ...prev,
                serviceLocation: locationData.street,
                clientName: userData.name,
                clientPhone: userData.phone,
                clientWhatsAppPhone: userData.phone,
              }));
            }
          } else {
            setServiceDetails((prev) => ({
              ...prev,
              clientName: userData.name,
              clientPhone: userData.phone,
              clientWhatsAppPhone: userData.phone,
            }));
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'User Error',
            text: 'User document not found.',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Fetch Error',
          text: `Error fetching user details: ${error.message}`,
        });
      }
    };

    fetchUserDetails();
  }, [currentUser.uid, locationData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServiceDetails((prev) => ({ ...prev, [name]: value }));
    
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setIsUploading(true); // Start upload state
        const fileRef = ref(storage, `service_images/${file.name + uuidv4()}`);
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        setServiceDetails((prev) => ({ ...prev, image: url }));
        setIsUploading(false); // End upload state
      } catch (error) {
        setIsUploading(false);
        Swal.fire({
          icon: 'error',
          title: 'Upload Error',
          text: `Error uploading file: ${error.message}`,
        });
      }
    }
  };

  const handleLocationChange = () => {
    navigate('/service-location', {
      state: {
        userData: userDetails,
        provider,
      },
    });
  };

  const uploadDefaultPhoto = async () => {
    try {
      const defaultPhotoRef = ref(storage, `service_images/default_${uuidv4()}.jpg`);
      const response = await fetch(DefaultServicePhoto);
      const blob = await response.blob();
      await uploadBytes(defaultPhotoRef, blob);
      const url = await getDownloadURL(defaultPhotoRef);
      return url;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Default Image Upload Error',
        text: `Error uploading default image: ${error.message}`,
      });
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isUploading) {
      return (
        <div className="flex justify-center items-center h-screen" role="status">
            <svg aria-hidden="true" className="text-gray-200 animate-spin dark:text-gray-600 fill-purple-800 w-14 h-14" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span className="sr-only">Loading...</span>
        </div>
    );
    }
  
    if (!serviceDetails.serviceDate || dateError) {
      Swal.fire({
        icon: 'error',
        title: 'Date Error',
        text: 'Please select a valid service date.',
      });
      return;
    }
  
    const formattedServiceDate = formatDate(serviceDetails.serviceDate);
    const providerCategory = provider.services_category || 'Unknown Category';
  
    try {
      setLoading(true);
  
      let imageUrl = serviceDetails.image;
      console.log("Image URL before submission:", imageUrl);
  
      // Check if an image was uploaded
      if (!imageUrl) {
        imageUrl = await uploadDefaultPhoto(); // Only run this if no image was uploaded
      }
  
      const bookingId = `BK${uuidv4()}`;
      const bookingDetails = {
        body: `Hello, ${provider.name}. You have a new service request! 🎉 ${bookingId} Please review and respond promptly.`,
        bookingId,
        providerId: provider.id,
        providerName: provider.name,
        providerCategory,
        providerService: provider.service,
        serviceLocation: serviceDetails.serviceLocation,
        clientName: serviceDetails.clientName,
        clientPhone: serviceDetails.clientPhone,
        clientWhatsAppPhone: serviceDetails.clientWhatsAppPhone,
        serviceDate: formattedServiceDate,
        serviceDescription: serviceDetails.serviceDescription,
        jobRated: false,
        image: imageUrl,
        imageUrls: null,
        status: 'Pending',
        title: '💥 New Service Request',
        bookingDate: registeredDate,
        userId: currentUser.uid,
      };
  
      const bookingDocRef = doc(db, 'serviceBookings', bookingId);
      await setDoc(bookingDocRef, bookingDetails);
  
      setLoading(false);
  
      Swal.fire({
        icon: 'success',
        title: 'Booking Successful',
        text: 'Service booked successfully!',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => navigate('/client-home', { state: { bookingId } }));
    } catch (error) {
      setLoading(false); // Ensure loading is reset in case of error
      Swal.fire({
        icon: 'error',
        title: 'Booking Error',
        text: `Error booking service: ${error.message}`,
      });
    }
  };
  

  

  return (
    <div className=" relative">
      <img src= {BackgroundImage} alt="Profile" className="bg-cover object-cover w-full h-screen blur-md hover:blur-0 grayscale hover:grayscale-0 transition ease-in-out duration-1000" />
      <form onSubmit={handleSubmit} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:-translate-y-0.1 hover:translate-x-0.1 hover:scale-105 space-y-6 border-2 border-gray-300 hover:border-gray-500 p-4 md:w-2/5 rounded-lg bg-slate-50 transition ease-in-out duration-1000">
      { loading && <div className=" fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
          <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>}
      <h2 className="text-2xl font-bold mb-6">Book a Service</h2>
        <div className="relative z-10 w-full group">
          <input
            type="text"
            name="serviceLocation"
            value={serviceDetails.serviceLocation}
            onChange={handleInputChange}
            ref={locationRef}
            onInput={validateLocation}
            required
            className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 ${locationError ? 'border-red-500' : 'border-gray-300'} appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
            placeholder=" "
          />
          <label
            htmlFor="serviceLocation"
            className={`peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] ${locationError ? 'peer-focus:text-red-600' : 'peer-focus:text-blue-600'} peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}
          >
            Service Location
          </label>
          <button
            type="button"
            onClick={handleLocationChange}
            className="text-blue-500 mt-2 underline"
          >
            Change Location
          </button>
        </div>

        <div className="relative z-10 w-full group">
          <input
            type="text"
            name="clientName"
            value={serviceDetails.clientName}
            onChange={handleInputChange}
            ref={nameRef}
            onInput={validateName}
            required
            className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 ${nameError ? 'border-red-500' : 'border-gray-300'} appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
            placeholder=" "
          />
          <label
            htmlFor="clientName"
            className={`peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] ${nameError ? 'peer-focus:text-red-600' : 'peer-focus:text-blue-600'} peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}
          >
            Name
          </label>
        </div>
  
        <div className="relative z-10 w-full group">
          <input
            type="text"
            name="clientPhone"
            value={serviceDetails.clientPhone}
            onChange={handleInputChange}
            required
            ref={phoneRef}
            onInput={validatePhone}
            className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 ${phoneError ? 'border-red-500' : 'border-gray-300'} appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
            placeholder=" "
          />
          <label
            htmlFor="clientPhone"
          className={`peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] ${phoneError ? 'peer-focus:text-red-600' : 'peer-focus:text-blue-600'} peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}
        >
          Phone
        </label>
      </div>
  
      <div className="relative z-10 w-full group">
        <input
          type="text"
          name="clientWhatsAppPhone"
          value={serviceDetails.clientWhatsAppPhone}
          onChange={handleInputChange}
          required
          ref={wphoneRef}
          onInput={validateWphone}
          className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 ${wphoneError ? 'border-red-500' : 'border-gray-300'} appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
          placeholder=" "
        />
        <label
          htmlFor="clientWhatsAppPhone"
          className={`peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] ${wphoneError ? 'peer-focus:text-red-600' : 'peer-focus:text-blue-600'} peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}
        >
          WhatsApp Phone
        </label>
      </div>
      <div className="relative z-10 w-full group">
        <input
          type="datetime-local"
          id="serviceDate"
          name="serviceDate"
          ref={dateRef}
          onInput={validateDate}
          value={serviceDetails.serviceDate}
          onChange={handleInputChange}
          required
          className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 ${dateError ? 'border-red-500' : 'border-gray-300'} appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
          placeholder=" "
        />
        <label
          htmlFor="serviceDate"
          className={`peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] ${dateError ? 'peer-focus:text-red-600' : 'peer-focus:text-blue-600'} peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}
        >
          Service Date
        </label>
        {dateError && <span className="text-red-500 text-sm">Please select a valid date.</span>}
      </div>

  
      <div className="relative z-10 w-full group">
        <textarea
          name="serviceDescription"
          value={serviceDetails.serviceDescription}
          onChange={handleInputChange}
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
        />
        <label
          htmlFor="serviceDescription"
          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Description
        </label>
      </div>
  
      <div className="relative z-10 w-full group">
        <input
          type="file"
          onChange={handleFileUpload}
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer l-2"
        />
        <label
          htmlFor="imageUpload"
          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Upload Image (optional)
        </label>
      </div>
  
      <button
        type="submit"
        className="text-white bg-purple-800 hover:bg-purple-950 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
      >
        Book Service
      </button>
    </form>
  </div>
  );
}
