import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFunctions } from 'firebase/functions';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useData } from '../contexts/DataContext';
import NotFound from '../assets/not-found-removebg-preview.png';
// Function to calculate distance between two points using the Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (angle) => angle * (Math.PI / 180);
    
    const R = 6371; // Radius of the Earth in km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon1 - lon2);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
    return parseFloat((R * c).toFixed(1)); // Distance in km
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Function to get coordinates from a location string using Google Maps Geocoding API
const getCoordinatesFromLocation = async (locationString) => {
    try {
        const apiKey = GOOGLE_MAPS_API_KEY;
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
            params: { address: locationString, key: apiKey },
        });
        if (response.data.status === 'OK') {
            const { lat, lng } = response.data.results[0].geometry.location;
            return { lat, lng };
        } else {
            throw new Error('Geocoding API error');
        }
    } catch (error) {
        console.error('Error getting coordinates:', error);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Failed to get coordinates for the location: ${locationString}`,
        });
        return null;
    }
};

// Function to fetch user location
const fetchUserLocation = async () => {
    try {
        const response = await fetch('https://ipinfo.io/json?token=39e3933d016b14');
        if (!response.ok) {
            throw new Error('Failed to fetch location data');
        }
        const locationData = await response.json();
        const [lat, lon] = locationData.loc.split(',');
        return { lat: parseFloat(lat), lng: parseFloat(lon) };
    } catch (error) {
        console.error('Error fetching location:', error);
        Swal.fire({
            icon: 'error',
            title: 'Location Error',
            text: 'Unable to retrieve location. Showing providers without distance.',
        });
        return null; // Return null if the location cannot be fetched
    }
};

export default function ShowServiceProvider() {
    const { service } = useParams();
    const decodedService = decodeURIComponent(service);
    const [providers, setProviders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const functions = getFunctions();
    const navigate = useNavigate();
    const { fetchProvidersByService } = useData();

    useEffect(() => {
        const fetchProviders = async () => {
            setLoading(true);
            try {
                const providersList = await fetchProvidersByService(decodedService);

                const location = await fetchUserLocation();

                const providersWithCoordinates = await Promise.all(
                    providersList.map(async (provider) => {
                        const locationCoords = await getCoordinatesFromLocation(provider.provider_location);
                        if (locationCoords) {
                            const distance = location
                                ? calculateDistance(
                                    location.lat,
                                    location.lng,
                                    locationCoords.lat,
                                    locationCoords.lng
                                  )
                                : null;
                            return { ...provider, distance, location: locationCoords };
                        }
                        return null;
                    })
                );

                const filteredProviders = providersWithCoordinates
                    .filter((provider) => provider !== null)
                    .sort((a, b) => (a.distance !== null && b.distance !== null ? a.distance - b.distance : 0));

                setProviders(filteredProviders);

                if (filteredProviders.length === 0) {
                    Swal.fire({
                        icon: 'info',
                        title: 'No Providers Found',
                        text: `There are no providers available for the service: ${service}.`,
                        timer: 2000,
                        showConfirmButton: false,
                        timerProgressBar: true,
                        willClose: () => {
                            navigate('/');
                        },
                    });
                }
            } catch (err) {
                setError(err.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `An error occurred: ${err.message}`,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchProviders();
    }, [service, functions, navigate, fetchProvidersByService, decodedService]);

    const handleRequestService = (provider) => {
        Swal.fire({
            title: `Request Service from ${provider.name}?`,
            text: "You are about to request a service. Proceed?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, request!',
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/request-service', { state: { provider } });
            }
        });
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen" role="status">
                <svg aria-hidden="true" className="text-gray-200 animate-spin dark:text-gray-600 fill-purple-800 w-14 h-14" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        );
    }

    if (error) {
        return <p className="text-red-500">Error: {error}</p>;
    }

  return (
    <div className='bg-white relative'>
      <div className='2xl:hidden bg-gray-800 h-52 absolute inset-0'></div>
      <h2 className='font text-2xl p-5 text-center text-white relative z-10 2xl:text-black 2xl:font-semibold '> {service} Providers</h2>
      {providers.length === 0 ? (
        <div className='flex flex-col items-center justify-center min-h-screen relative z-10'>
        <img src={NotFound} alt='No Bookings' className='w-3/5 grayscale  opacity-50 lg:w-2/5' />
        <p className='text-3xl sm:text-6xl font-bold text-gray-800 opacity-50'>No Providers Found</p>
        </div>
      ) : (
        <ul>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-col-5 gap-4 mx-8 '>
            
          {providers.map(({ id, isVerified, profile_imageUrl, profileImageUrl, name, email, phone, rating, services_category, services_sub_category, user_country,  totalUserRated, distance }) => (
            isVerified && (
              <div
                key={id}
                className={` border border-gray-300 rounded-xl shadow hover:shadow-2xl dark:bg-gray-800 dark:border-gray-700 bg-blue-950 transition duration-300 ease-in-out relative z-10 `}
              >
                
                <div className="text-center bg-blue-950">
                    <img className="h-32 w-32 rounded-full border-4 border-white dark:border-gray-800 mx-auto my-4 object-cover"
                        src={profile_imageUrl || profileImageUrl} alt=""/>
                    <div className="py-2">
                        <h3 class="font-bold text-2xl text-white dark:text-white mb-1">{name}</h3>
                        <div className="inline-flex text-white dark:text-white items-center">
                            <svg class="h-5 w-5 text-gray-400 dark:text-white mr-1" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path class=""
                                    d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 1 0-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                            </svg>
                            {user_country}
                        </div>
                    </div>
                </div >
               
                <div className=' flex flex-col items-center justify-center border-gray-400 pb-5 bg-white '>
                <h1 className="text-2xl font-semibold text-gray-800 px-7">{service || 'No category available'}</h1>
                <div className="flex items-center mt-4 text-gray-700">
                <svg class="h-6 w-6 fill-current" viewBox="0 0 512 512">
                    <path d="M437.332 80H74.668C51.199 80 32 99.198 32 122.667v266.666C32 412.802 51.199 432 74.668 432h362.664C460.801 432 480 412.802 480 389.333V122.667C480 99.198 460.801 80 437.332 80zM432 170.667L256 288 80 170.667V128l176 117.333L432 128v42.667z"/>
                </svg>
                <h1 className="px-2 text-sm">{email}</h1>
            </div>
            <div className="flex items-center mt-4 text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-6 w-6 fill-current">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
              </svg>

                <h1 className="px-2 text-sm">{phone}</h1>
            </div>


            <div className="flex items-center py-2">
                <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                </svg>
                <p className="ms-2 text-sm font-bold text-gray-900 dark:text-white">{rating}</p>
                <span class="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>
                <p className="text-sm font-medium text-gray-900 dark:text-white"> {totalUserRated} reviews</p>
            </div>
            <div className='border-4 border-gray-800 p-1 rounded-full shadow-md bg-white absolute left-0 top-0 m-2 '>
            <hi>{distance} km </hi>
            </div>
                  {/* <p className='mb-3 font-normal text-gray-700 dark:text-gray-400'>Sub-Category: {services_sub_category.join(', ') || 'No sub-category available'}</p> */}
                  <button
                    className="flex items-center rounded-full bg-purple-800  text-white dark:text-white antialiased font-bold hover:bg-purple-950 px-5 py-2 transition ease-in-out duration-300"
                    type="button"
                    onClick={() => handleRequestService({ id, name, email, phone, rating, services_category, service, services_sub_category })}
                  >
                    Request Service
                    <svg
                      className="rtl:rotate-180 w-3.5 h-3.5 ms-2 inline-block"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </button>
                  
                </div>
                
              </div>
            )
          ))}
        </div>
        </ul>
      )}
    </div>
  );
}
