import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import Modal from 'react-modal';
import axios from 'axios';
import Swal from 'sweetalert2';
import Unavailable from '../assets/bg-image-background.png';


Modal.setAppElement('#root');

const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '250px',
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};


const geocode = async (address) => {
  try {
    const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address,
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      },
    });
    const location = response.data.results[0]?.geometry.location;
    return location;
  } catch (error) {
    console.error('Error fetching geocode data:', error);
    return null;
  }
};

export default function PartnerAcceptedRequest() {
  const { currentUser, refreshUserData } = useAuth();
  const [filteredServices, setFilteredServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState('Accepted');
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [clientInfo, setClientInfo] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isClick, setIsClick] = useState(true); // Flag to determine click vs. drag
  const [draggedDistance, setDraggedDistance] = useState(0);
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const statusOptions = ['Accepted', 'Ongoing', 'Completed', 'Cancelled', 'Pending'];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });


  const handleStatusChange = async () => {
    try {
      const bookingRef = doc(db, 'serviceBookings', selectedService.bookingId);
      await updateDoc(bookingRef, { status: selectedService.status });
  
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Booking status updated.',
        showConfirmButton: false,
        timer: 1500,
      });
  
      // Refetch the list of booked services after updating status
      fetchBookedServices(); 
  
      setModalIsOpen(false); // Close modal after success
      refreshUserData();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update booking status.',
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("Error updating booking status:", error);
    }
  };

  const fetchBookedServices = useCallback(async () => {
    try {
      const userId = currentUser.uid;
      const q = query(collection(db, 'serviceBookings'), where('providerId', '==', userId));
      const querySnapshot = await getDocs(q);
      const services = querySnapshot.docs.map(doc => doc.data());
  
      // Filter and sort the services based on status and bookingDate
      const sortedFilteredServices = services
        .filter(service => service.status === statusFilter)
        .sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate)); // Sort by bookingDate descending
  
      setFilteredServices(sortedFilteredServices);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch booked services.',
      });
      console.error("Error fetching booked services:", error);
    }
  }, [currentUser.uid, statusFilter]);

  useEffect(() => {
    fetchBookedServices();
  }, [currentUser.uid, statusFilter, fetchBookedServices]);
  

  useEffect(() => {
    const fetchLocation = async () => {
      if (selectedService) {
        const location = await geocode(selectedService.serviceLocation);
        if (location) {
          setMapCenter({ lat: location.lat, lng: location.lng });
        }
      }
    };

    fetchLocation();
  }, [selectedService]);

  useEffect(() => {
    const fetchClientInfo = async () => {
      if (selectedService) {
        try {
          const userRef = doc(db, 'users', selectedService.userId);
          const userDoc = await getDoc(userRef);
          setClientInfo(userDoc.data());
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch client information.',
            showConfirmButton: false,
            timer: 1500,
          });
          console.error("Error fetching client info:", error);
        }
      }
    };

    fetchClientInfo();
  }, [selectedService]);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setModalIsOpen(true);
  };

  
  const handleFilterChange = (status) => {
    if (isClick){
    setStatusFilter(status);
  }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
    setIsClick(true); // Assume it's a click until proven otherwise
    setDraggedDistance(0); // Reset drag distance
  };

  // Mouse move: Track drag distance to determine whether it was a drag or click
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 3; // Adjust scroll speed
    e.currentTarget.scrollLeft = scrollLeft - walk;
    setDraggedDistance(draggedDistance + Math.abs(x - startX)); // Accumulate drag distance
    if (draggedDistance > 5) {
      setIsClick(false); // Consider it a drag if moved more than 5px
    }
  };

  // Mouse up: Finalize drag and prevent click if dragging occurred
  const handleMouseUp = () => {
    setIsDragging(false);
    if (draggedDistance > 5) {
      setIsClick(false); // Confirm it's a drag, not a click
    }
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

    // Handle touch events for scrolling
    const handleTouchStart = (e) => {
      setIsDragging(true);
      setStartX(e.touches[0].pageX - e.currentTarget.offsetLeft);
      setScrollLeft(e.currentTarget.scrollLeft);
      setIsClick(true);
      setDraggedDistance(0);
    };
  
    const handleTouchMove = (e) => {
      if (!isDragging) return;
      const x = e.touches[0].pageX - e.currentTarget.offsetLeft;
      const walk = (x - startX) * 3;
      e.currentTarget.scrollLeft = scrollLeft - walk;
      setDraggedDistance(draggedDistance + Math.abs(x - startX));
      if (draggedDistance > 5) {
        setIsClick(false);
      }
    };
  
    const handleTouchEnd = () => {
      setIsDragging(false);
      if (draggedDistance > 5) {
        setIsClick(false);
      }
    };


  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return(
    <div className="flex justify-center items-center h-screen"  role="status">
        <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span className="sr-only">Loading...</span>
    </div> )

  return (
    <div className="p-4 relative">
     <div className='2xl:hidden bg-gray-800 h-16 absolute inset-0'></div>
      <h1 className="text-2xl font mb-4 text-center relative z-0 text-white 2xl:text-black 2xl:font-semibold "> Service Requests</h1>
      
      <div className="mb-4 flex flex-row items-center justify-center">
  <div
    className="flex overflow-x-hidden  px-2"
    onMouseDown={handleMouseDown}
    onMouseLeave={handleMouseLeave}
    onMouseUp={handleMouseUp}
    onMouseMove={handleMouseMove}
    onTouchStart={handleTouchStart}  // Touch events
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
    
    style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
  >
    {statusOptions.map((status) => {
      // Determine border and background color based on status
      let borderColor = '';
      let bgColor = '';
      switch (status) {
        case 'Pending':
          borderColor = 'border-black';
          bgColor = statusFilter === 'Pending' ? 'transition ease-in-out duration-200 bg-black text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        case 'Accepted':
          borderColor = 'border-blue-500';
          bgColor = statusFilter === 'Accepted' ? 'transition ease-in-out duration-200 bg-blue-500 text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        case 'Ongoing':
          borderColor = 'border-orange-500';
          bgColor = statusFilter === 'Ongoing' ? 'transition ease-in-out duration-200 bg-orange-500 text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        case 'Completed':
          borderColor = 'border-green-500';
          bgColor = statusFilter === 'Completed' ?  'transition ease-in-out duration-200 bg-green-500 text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        case 'Cancelled':
          borderColor = 'border-red-500';
          bgColor = statusFilter === 'Cancelled' ? 'transition ease-in-out duration-200 bg-red-700 text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        default:
          break;
      }

      return (
        <button
          key={status}
          onClick={() => handleFilterChange(status)}
          draggable={false}
          className={`mr-2 my-2 px-4 py-2 sm:mx-7 md:mx-9 lg:mx-11 xl:mx-14 lg:px-6 inline-block rounded-full border-2 ${borderColor} ${bgColor}`}
        >
          {status}
        </button>
      );
    })}
  </div>
</div>

      {filteredServices.length === 0 ? (
        <div className='flex flex-col items-center justify-center min-h-screen relative z-10'>
        <img src={Unavailable} alt='No Bookings' className='w-3/5 grayscale  opacity-50 lg:w-2/5' />
        <p className='text-3xl sm:text-6xl font-bold text-gray-800 opacity-50'>No Bookings Found</p>
        </div>
      ) : (
        <ul className="divide-y divide-gray-100">
          {filteredServices.map(service => (
            <li
              key={service.bookingId}
              onClick={() => handleServiceClick(service)}
              className="flex justify-between gap-x-4 py-5 cursor-pointer mx-5 hover:border-2 hover:bg-slate-100 hover:shadow-md rounded-full p-5 transtion ease-in-out duration-100 mt-1 border-gray-200 border-2"
            >
               <div className='flex min-w-0 gap-x-4'>
              <img src={service.image} alt="Service" className="h-12 w-12 flex-none rounded-full bg-gray-50" />
              <div className="min-w-0 flex-auto">
              <p className="text-md font-semibold leading-6 text-gray-900">{service.clientName}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">Booking Id: {service.bookingId || 'No service specified'}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">Service Requested: {service.providerService|| 'No service specified'}</p>
            </div>
            </div>
              <div className="hidden shrink-0 md:flex md:flex-col md:items-end">
              <p className="text-sm leading-6 text-gray-900">{service.status}</p>
              <p className="mt-1 text-xs leading-5 text-gray-500"><strong>Booking Date:</strong> {new Date(service.bookingDate).toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
              })}</p>
              <p className="mt-1 text-xs leading-5 text-gray-500" ><strong>Service Date:</strong> {new Date(service.serviceDate).toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
              })}</p>
            </div>
            </li>
          ))}
        </ul>
      )}

{selectedService && (
  <Modal
    isOpen={modalIsOpen}
    onRequestClose={() => setModalIsOpen(false)}
    className="relative bg-white p-8 rounded-lg shadow-2xl w-full max-w-2xl mx-auto max-h-screen overflow-y-auto"
    overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center"
  >
    {/* Close button in the top-right corner */}
    <button
      className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
      onClick={() => setModalIsOpen(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800 border-b pb-2 border-gray-300">Booking Details</h2>

    <div className="space-y-4 text-sm text-gray-700">
      <p><strong>Booking ID:</strong> {selectedService.bookingId}</p>
      <p><strong>Change Booking Status:</strong>
        <select
          value={selectedService.status}
          onChange={(e) => setSelectedService(prev => ({ ...prev, status: e.target.value }))}
          className="ml-2 p-1 border border-gray-300 rounded"
        >
          {statusOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </p>
      <p><strong>Booking Date:</strong> {new Date(selectedService.bookingDate).toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      })}</p>
      <p><strong>Service Date:</strong> {new Date(selectedService.serviceDate).toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      })}</p>
      <p><strong>Service Location:</strong> {selectedService.serviceLocation}</p>
      <p><strong>Service Requested:</strong> {selectedService.providerService}</p>
      <p><strong>Service Description:</strong> {selectedService.serviceDescription || 'No description provided'}</p>
         {/* Provided Image */}
         <div>
        <p className='inline-block'><strong>Provided Image:</strong></p>
        <img
          src={selectedService.image}
          alt="Client Profile"
          width="100"
          className="w-10 h-10 rounded-full inline-block ml-2 cursor-pointer hover:scale-105 transition-transform duration-200 ease-in-out"
          onClick={() => setImageModalOpen(true)} // Open modal on click
        />
      </div>
    </div>

    {/* Larger Image Modal */}
    {imageModalOpen && (
      <Modal
        isOpen={imageModalOpen}
        onRequestClose={() => setImageModalOpen(false)}
        className="relative p-4 shadow-lg w-auto max-w-xl "
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center"
      >
        <button
          className="absolute top-10 right-10 text-gray-500 hover:text-gray-700"
          onClick={() => setImageModalOpen(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        
        <img
          src={selectedService.image}
          alt="Client Profile Full"
          className=" w-full h-full object-cover"
        />
      </Modal>
    )}

    {/* Google Map */}
    <div className="w-full h-64 mb-6 mt-4 overflow-hidden rounded-lg shadow-inner">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={mapCenter}
        options={options}
      >
        <Marker position={mapCenter} />
      </GoogleMap>
    </div>

    {/* Client Info */}
    <div className="mt-4 p-4 border-t border-gray-300">
      <h3 className="text-lg font-semibold mb-2">Client Information</h3>
      <img src={clientInfo.profileImageUrl} alt="Client Profile" width="100" className="w-10 h-10 rounded-full object-cover" />
      <p><strong>Name:</strong> {selectedService.clientName}</p>
      <p><strong>Email:</strong> {clientInfo.email}</p>
      <p><strong>WhatsApp:</strong> {selectedService.clientWhatsAppPhone}</p>
    </div>

    {/* WhatsApp and Email buttons */}
    <div className="mt-4 space-y-2">
      <a
        href={`https://wa.me/${selectedService.clientWhatsAppPhone}`}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block px-4 py-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-600 mr-2"
      >
        Chat on WhatsApp
      </a>
      <a
        href={`mailto:${clientInfo.email}`}
        className="inline-block px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition ease-in-out duration-300"
      >
        Send an Email
      </a>
    </div>

    {/* Action buttons */}
    <div className="mt-4 flex gap-2 justify-end">
      <button
        onClick={handleStatusChange}
        className="px-6 py-3 bg-blue-500 text-white font-medium rounded-md shadow hover:bg-blue-600 transition duration-200 focus:ring-4 focus:ring-blue-300 focus:ring-opacity-50"
      >
        Update Status
      </button>
      <button
        onClick={() => setModalIsOpen(false)}
        className="px-6 py-3 bg-gray-500 text-white font-medium rounded-md shadow hover:bg-gray-600 transition duration-200 focus:ring-4 focus:ring-gray-300 focus:ring-opacity-50"
      >
        Close
      </button>
    </div>
  </Modal>
)}
    </div>
  );
}
