import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../FirebaseConfig';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import Swal from 'sweetalert2';
import { useAuth } from '../../contexts/AuthContext';
import VukaLogo from '../../assets/fulllogo-removebg-preview.svg';
const libraries = ['places'];
const mapContainerStyle = { width: '100%', height: '400px' };
const options = { disableDefaultUI: true, zoomControl: true };
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const IP_INFO_KEY = process.env.REACT_APP_IP_INFO_API_KEY;

export default function Location() {
    const { refreshUserData } = useAuth();
    const [selectedLocation, setSelectedLocation] = useState({
        date: '',
        id: '',
        label: '',
        latLng: '',
        street: '',
    });
    const [coordinates, setCoordinates] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const { userData } = state || {};
    const { id, name, email, phone, gender, profileImageUrl, isProvider } = userData || {};
    const navigate = useNavigate();
    const [registeredDate, setRegisteredDate] = useState('');
    const inputRef = useRef(null);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const getFormattedDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = date.getHours() % 12 || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
        setRegisteredDate(formattedDate);
    };

    useEffect(() => {
        getFormattedDate();
    }, []);

    useEffect(() => {
        if (!isLoaded) return;

        const fetchUserLocation = async () => {
            try {
                const response = await fetch(`https://ipinfo.io/json?token=${IP_INFO_KEY}`);
                if (!response.ok) throw new Error('Failed to fetch location data');
                const locationData = await response.json();
                const [lat, lon] = locationData.loc.split(',');
                const initialCoordinates = { lat: parseFloat(lat), lng: parseFloat(lon) };
                setCoordinates(initialCoordinates);
                setMarkerPosition(initialCoordinates);
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Location Fetch Error',
                    text: `Error fetching location: ${error.message}`,
                });
            }
        };

        fetchUserLocation();
    }, [isLoaded]);

    const handleMapClick = (event) => {
        const newPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        setMarkerPosition(newPosition);
        setSelectedLocation(prev => ({
            ...prev,
            latLng: `Latitude: ${newPosition.lat} Longitude: ${newPosition.lng}`,
        }));
        fetchStreetAddress(newPosition.lat, newPosition.lng);
    };

    const fetchStreetAddress = async (lat, lng) => {
        try {
            if (typeof window.google === 'undefined' || !window.google.maps) {
                throw new Error('Google Maps API is not loaded');
            }

            const geocoder = new window.google.maps.Geocoder();
            const results = await geocoder.geocode({ location: { lat, lng } });

            if (results.results[0]) {
                setSelectedLocation(prev => ({
                    ...prev,
                    street: results.results[0].formatted_address || 'Street address not found',
                }));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Geocoding Error',
                    text: 'No results found for the given coordinates.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Geocoding Error',
                text: `Error fetching street address: ${error.message}`,
            });
        }
    };

    useEffect(() => {
        if (inputRef.current && isLoaded && window.google) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

            const handlePlaceSelect = () => {
                const place = autocomplete.getPlace();
                const location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                setMarkerPosition(location);
                setCoordinates(location);
                setAddress(place.formatted_address);

                // Update selected location and fetch street address
                setSelectedLocation(prev => ({
                    ...prev,
                    latLng: `Latitude: ${location.lat} Longitude: ${location.lng}`,
                    street: place.formatted_address,
                }));
                fetchStreetAddress(location.lat, location.lng); // Fetch the full address
            };

            autocomplete.addListener('place_changed', handlePlaceSelect);
        }
    }, [inputRef, isLoaded]);

    const handleNext = async () => {
        try {
            if (!id) {
                Swal.fire({
                    icon: 'error',
                    title: 'User ID Error',
                    text: 'User ID is not defined',
                });
                return;
            }
            setLoading(true);

            const currentDate = new Date().toLocaleString();
            const locationId = id;

            const userDocRef = doc(db, 'users', id);
            await setDoc(userDocRef, {
                name,
                email,
                id,
                phone,
                gender,
                profileImageUrl,
                isProvider,
                regDate: registeredDate,
                provider_location: isProvider ? selectedLocation.street : "",
                appOwner: 'NotOwner',
                deviceToken: "",
                isVerified: false,
                jobs_done: 0,
                rating: 0,
                services_category: "",
                status: "NotVerified",
                subscribed_services: 0,
                totalUserRated: 0,
                userRole: "user",
                user_country: "Kenya",
            }, { merge: true });

            const locationDocRef = doc(db, 'users', id, 'Locations', id);
            await setDoc(locationDocRef, {
                date: currentDate,
                id: locationId,
                label: selectedLocation.label,
                latLng: selectedLocation.latLng,
                street: selectedLocation.street,
                regDate: registeredDate,
            });
            setLoading(false);
            
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Your location has been saved successfully.',
                showConfirmButton: false,
                timer: 2000,
            }).then(() => {
                if (isProvider) {
                    navigate('/provider-details', { state: { userId: id, userData, selectedLocation } });
                } else {
                    navigate('/client-home');
                    refreshUserData();
                }
            });
            

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error Handling Next Step',
                text: `Error handling next step: ${error.message}`,
            });
            setLoading(false);
        }
    };

    if (loadError) {
        Swal.fire({
            icon: 'error',
            title: 'Google Maps Load Error',
            text: 'Error loading Google Maps',
        });
        return <div>Error loading Google Maps</div>;
    }


    return (
      <div className="min-h-screen flex">
         { loading && <div className=" fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
          <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>}
            <div className="flex flex-col justify-center py-12 px-6 md:px-8 lg:px-10 w-full md:w-2/3 bg-white">
                <div className="2xl:mx-40 p-5">
                    <h2 className="font-bold text-3xl mb-6 text-center text-gray-800">Select Your Location</h2>
                    {coordinates && (
                        <div className="rounded-lg shadow-md overflow-hidden mb-5">
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                center={coordinates}
                                zoom={15}
                                options={options}
                                onClick={handleMapClick}
                            >
                                {markerPosition && <Marker position={markerPosition} />}
                            </GoogleMap>
                        </div>
                    )}
                    <div className="flex flex-col items-center mb-6">
                        <label htmlFor="search" className="block mb-2 text-sm font-medium text-gray-900 text-center">Search Location</label>
                        <input
                            type="text"
                            ref={inputRef}
                            className="block w-full md:w-1/2 px-4 py-2.5 mb-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:ring-purple-800 focus:border-purple-800 transition duration-300"
                            placeholder="Search for a place..."
                        />
                        <label htmlFor="label" className="block mb-2 text-sm font-medium text-gray-900 text-center">Location</label>
                        <select
                            id="label"
                            value={selectedLocation.label}
                            onChange={(e) => setSelectedLocation((prev) => ({ ...prev, label: e.target.value }))}
                            className="block w-full md:w-1/2 px-4 py-2.5 mb-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:ring-purple-800 focus:border-purple-800 transition duration-300"
                        >
                            <option value="" className="text-gray-900">Select Label</option>
                            <option value="home" className="text-gray-900">Home</option>
                            <option value="office" className="text-gray-900">Office</option>
                            <option value="other" className="text-gray-900">Other</option>
                        </select>
                        <p className="text-gray-600">Selected Address: <span className="font-semibold">{address}</span></p>
                    </div>
                    <button
                        className="w-full bg-purple-800 text-white py-3 rounded-lg hover:bg-purple-950 transition duration-300"
                        onClick={handleNext}
                    >
                        {isProvider ? "Next" : "Sign Up"}
                    </button>
                </div>
            </div>
            <div className="hidden md:flex w-1/3 bg-gray-800 items-center justify-center p-8 ">
          <div className="text-white ml-10 text-center">
          <img src={VukaLogo} alt="Vuka Logo" className='' />
          {/* <h1 className="text-3xl font-bold mb-5 border-b-2 border-white inline-block">Our Process</h1> */}
            <h1 className="text-xl font-semibold mb-5 lg:text-3xl  inline-block border-opacity-50"><span className="text-green-500 inline-block">✔</span>  Find a service you need</h1>
            <div className=''> 
            <h4 className="text-sm md:text-base font-medium mt-2 mb-4 inline-block"> Pick a Service Provider </h4>
            </div>
            {/* <div className=''>
            <span className="text-green-500 inline-block">✔</span> 
            <h4 className="text-sm font-medium mt-2 mb-4 inline-block">Pick the provider you want.</h4>
            </div> */}
            <h1 className="text-xl font-semibold mb-5 lg:text-3xl  inline-block border-opacity-50"><span className="text-green-500 inline-block">✔</span> Schedule your task </h1>
            <div className=''>
            <h4 className="text-sm md:text-base font-medium mt-2 mb-4 inline-block"> Enter your location.</h4>
            </div>
            {/* <div className=''>
            <span className="text-green-500 inline-block">✔</span> 
            <h4 className="text-sm font-medium mt-2 mb-4 inline-block">Pick a date and time that works for you.</h4>
            </div> */}
            <h1 className="text-xl font-semibold mb-5 lg:text-3xl  inline-block border-opacity-50"><span className="text-green-500 inline-block">✔</span> Get value for your Money</h1>
            <div className=''>
            <h4 className="text-sm md:text-base font-medium mt-2 mb-4 inline-block">Get your service done by professionals</h4>
            </div>
            {/* <div className=''>
            <span className="text-green-500 inline-block">✔</span> 
            <h4 className="text-sm font-medium mt-2 mb-4 inline-block">Get the results you desire.</h4>
            </div> */}
            
          </div>
        </div>
        </div>
    );
}