import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import NotFound from '../assets/not-found-removebg-preview.png';

export default function PartnerReviews() {
  const { userData } = useAuth();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(true); // Start loading when fetching starts
        setError(null); // Reset error before fetching

        if (userData && userData.id) {
          const reviewsRef = collection(db, 'Ratings');
          const q = query(reviewsRef, where('providerId', '==', userData.id));
          const querySnapshot = await getDocs(q);
          const reviewsList = querySnapshot.docs.map(doc => doc.data());
          setReviews(reviewsList);
        } else {
          // Show error if userData is unavailable
          // Swal.fire({
          //   icon: 'error',
          //   title: 'User Data Unavailable',
          //   text: 'User data is not available. Please check your login status.',
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
          setError('User data is not available.');
        }
      } catch (error) {
        console.error('Error fetching reviews:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error Fetching Reviews',
          text: 'Failed to fetch reviews. Please try again later.',
          showConfirmButton: false,
          timer: 1500,
        });
        setError('Failed to fetch reviews.');
      } finally {
        setLoading(false); // Stop loading once the process is finished
      }
    };

    // Call fetchReviews whenever userData changes
    fetchReviews();
  }, [userData, db, navigate]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen" role="status">
        <svg
          aria-hidden="true"
          className="text-gray-200 animate-spin dark:text-gray-600 fill-purple-800 w-14 h-14"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (error) {
    // Handle case when there is an error
    return <div>Error: {error}</div>;
  }

  if (!reviews || reviews.length === 0) {
    // Handle case where no reviews are found
    return (
      <div className="flex flex-col items-center justify-center min-h-screen relative z-10">
        <img src={NotFound} alt="No Reviews" className="w-3/5 grayscale opacity-50 lg:w-2/5" />
        <p className="mt-4 text-lg text-gray-500">No reviews found.</p>
      </div>
    );
  }
  return (
    <div className='relative'>
      <div className='2xl:hidden bg-gray-800 h-52 absolute inset-0'></div>
    <div className="max-w-4xl mx-auto p-6  relative z-10">
       
      <h2 className="text-3xl font mb-8 text-white 2xl:text-black 2xl:font-medium ">Reviews</h2>
      {reviews.length > 0 ? (
        reviews.map((review, index) => (
          <div key={index} className="bg-white p-6 mb-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex items-start">
            <div className="flex-shrink-0">
              <div className="inline-block relative">
                <div className="relative w-16 h-16 rounded-full overflow-hidden">
                  <img
                    className="absolute top-0 left-0 w-full h-full bg-cover object-fit object-cover"
                    src={review.image || 'https://via.placeholder.com/150'}
                    alt="Service"
                  />
                  <div className="absolute top-0 left-0 w-full h-full rounded-full shadow-inner"></div>
                </div>
              </div>
            </div>
            <div className="ml-6">
              <p className="text-gray-600 font-bold">{review.clientName}</p>
              <div className="flex items-center mt-1">
                {[...Array(5)].map((_, i) => (
                  <svg
                    key={i}
                    className={`w-4 h-4 ${i < review.rating ? 'text-yellow-300' : 'text-gray-400'}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                  </svg>
                ))}
              </div>
              <p className="text-gray-700 mt-2">
                <span className="font-semibold">Service:</span> {review.providerService}
              </p>
              <p className="text-gray-700">
                <span className="font-semibold">Service Date:</span>
                {new Date(review.serviceDate).toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
              })}
              </p>
              <p className="text-gray-700">
                <span className="font-semibold">Review:</span> {review.review}
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className='flex flex-col items-center justify-center min-h-screen relative z-10'>
        <img src={NotFound} alt='No Bookings' className='w-3/5 grayscale  opacity-50 lg:w-2/5' />
        <p className='text-3xl sm:text-6xl font-bold text-gray-800 opacity-50'>No Reviews yet</p>
        </div>
      )}
    </div>
    </div>
  );
}
