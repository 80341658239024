import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import { useAuth } from '../contexts/AuthContext';
import Swal from 'sweetalert2';
import VukaLogo from '../assets/fulllogo-removebg-preview.svg';



const libraries = ['places'];
const mapContainerStyle = { width: '100%', height: '400px' };
const options = { disableDefaultUI: true, zoomControl: true };
const IP_INFO_KEY = process.env.REACT_APP_IP_INFO_API_KEY;

export default function ServiceLocation() {
  const navigate = useNavigate();
  const [markerPosition, setMarkerPosition] = useState(null);
  const [label, setLabel] = useState('office');
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const { currentUser } = useAuth();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const inputRef = useRef(null); // Ref for the search input

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const response = await fetch(`https://ipinfo.io/json?token=${IP_INFO_KEY}`);
        if (!response.ok) throw new Error('Failed to fetch location data');
        const locationData = await response.json();
        const [lat, lon] = locationData.loc.split(',');
        setCoordinates({ lat: parseFloat(lat), lng: parseFloat(lon) });
      } catch (error) {
        console.error('Error fetching location:', error);
        Swal.fire({ icon: 'error', title: 'Error fetching location', text: error.message });
      }
    };
    fetchUserLocation();
  }, []);

  const onMapClick = useCallback((event) => {
    const newPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setMarkerPosition(newPosition);
  }, []);


  const handleSaveLocation = async () => {
    if (markerPosition && label) {
      try {
        const locationId = uuidv4();
        const userId = currentUser.uid;
        const locationData = {
          id: locationId,
          label,
          latLng: markerPosition,
          street: address,
          date: new Date().toLocaleString(),
        };
        const locationDocRef = doc(db, 'users', userId, 'Locations', locationId);
        await setDoc(locationDocRef, locationData);
        Swal.fire({ icon: 'success', title: 'Location saved', text: 'Your service location has been successfully saved.', showConfirmButton: false, timer: 1500 });
        navigate('/request-service', { state: { locationData } });
      } catch (error) {
        console.error('Error saving location:', error);
        Swal.fire({ icon: 'error', title: 'Error', text: 'Failed to save the location. Please try again.' });
      }
    } else {
      Swal.fire({ icon: 'warning', title: 'Incomplete Information', text: 'Please select a location and provide a label for it.' });
    }
  };

  const handlePlaceSelect = useCallback((place) => {
  const location = {
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
  };
  setMarkerPosition(location);
  setAddress(place.formatted_address);
  setCoordinates(location); // Update the map center to the new location
}, []);

const handleSearch = useCallback(() => {
  const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    handlePlaceSelect(place);
  });
}, [handlePlaceSelect]);

useEffect(() => {
  if (inputRef.current) {
    handleSearch();
  }
}, [ handleSearch]);


  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div className="min-h-screen flex">
      <div className="flex flex-col justify-center py-12 px-6 md:px-8 lg:px-10 w-full md:w-2/3 bg-white">
        <div className="2xl:mx-40 p-5">
          <h2 className="font-bold text-3xl mb-6 text-center text-gray-800">Select Service Location</h2>
          {coordinates && (
            <div className="rounded-lg shadow-md overflow-hidden mb-5">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={coordinates}
                zoom={15}
                options={options}
                onClick={onMapClick}
              >
                {markerPosition && (
                  <Marker position={markerPosition} />
                )}
              </GoogleMap>
            </div>
          )}
          <div className="flex flex-col items-center mb-6">
            <label htmlFor="search" className="block mb-2 text-sm font-medium text-gray-900 text-center">Search Location</label>
            <input
              type="text"
              ref={inputRef}
              className="block w-full md:w-1/2 px-4 py-2.5 mb-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:ring-purple-800 focus:border-purple-800 transition duration-300"
              placeholder="Search for a place..."
            />
            <label htmlFor="label" className="block mb-2 text-sm font-medium text-gray-900 text-center">Location</label>
            <select
              id="label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              className="block w-full md:w-1/2 px-4 py-2.5 mb-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:ring-purple-800 focus:border-purple-800 transition duration-300"
            >
              <option value="" className="text-gray-900">Select Label</option>
              <option value="home" className="text-gray-900">Home</option>
              <option value="office" className="text-gray-900">Office</option>
              <option value="other" className="text-gray-900">Other</option>
            </select>
            <p className="text-gray-600">Selected Address: <span className="font-semibold">{address}</span></p>
          </div>
          <button
            className="w-full bg-purple-800 text-white py-3 rounded-lg hover:bg-purple-950 transition duration-300"
            onClick={handleSaveLocation}
          >
            Save Location
          </button>
        </div>
      </div>
     {/* Right Side */}
     <div className="hidden md:flex w-1/3 bg-gray-800 items-center justify-center p-8 ">
          <div className="text-white ml-10 text-center">
          <img src={VukaLogo} alt="Vuka Logo" className='' />
          {/* <h1 className="text-3xl font-bold mb-5 border-b-2 border-white inline-block">Our Process</h1> */}
            <h1 className="text-xl font-semibold mb-5 lg:text-3xl  inline-block border-opacity-50"><span class="text-green-500 inline-block">✔</span>  Find a service you need</h1>
            <div className=''> 
            <h4 className="text-sm md:text-base font-medium mt-2 mb-4 inline-block"> Pick a Service Provider </h4>
            </div>
            {/* <div className=''>
            <span class="text-green-500 inline-block">✔</span> 
            <h4 className="text-sm font-medium mt-2 mb-4 inline-block">Pick the provider you want.</h4>
            </div> */}
            <h1 className="text-xl font-semibold mb-5 lg:text-3xl  inline-block border-opacity-50"><span class="text-green-500 inline-block">✔</span> Schedule your task </h1>
            <div className=''>
            <h4 className="text-sm md:text-base font-medium mt-2 mb-4 inline-block"> Enter your location.</h4>
            </div>
            {/* <div className=''>
            <span class="text-green-500 inline-block">✔</span> 
            <h4 className="text-sm font-medium mt-2 mb-4 inline-block">Pick a date and time that works for you.</h4>
            </div> */}
            <h1 className="text-xl font-semibold mb-5 lg:text-3xl  inline-block border-opacity-50"><span class="text-green-500 inline-block">✔</span> Get value for your Money</h1>
            <div className=''>
            <h4 className="text-sm md:text-base font-medium mt-2 mb-4 inline-block">Get your service done by professionals</h4>
            </div>
            {/* <div className=''>
            <span class="text-green-500 inline-block">✔</span> 
            <h4 className="text-sm font-medium mt-2 mb-4 inline-block">Get the results you desire.</h4>
            </div> */}
            
          </div>
        </div>
    </div>
  );
}
