import React, { useState, useEffect } from 'react'; // Import useEffect
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import vukaLogo from '../assets/fulllogo-removebg-preview.svg';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export default function NavBar() {
  const navigate = useNavigate();
  const { currentUser, logout, isProvider, userData } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  // Fetch categories from Firestore
  useEffect(() => {
    const fetchCategories = async () => {
      const db = getFirestore();
      try {
        const categoriesSnapshot = await getDocs(collection(db, 'Categories'));
        const categoriesList = categoriesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(categoriesList);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategorySelect = (category) => {
    const path = currentUser
      ? `/client-display-services/${category}`
      : `/display-services/${category}`;
    navigate(path);
    setMobileMenuOpen(false); // Close mobile menu if open
  };

  // Handles logout functionality
  async function handleLogout() {
    try {
      await logout();
      navigate('/login');
      setUserMenuOpen(false);
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  }

  return (
    <nav className="bg-gray-800 relative z-50">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-20 items-center justify-between">
          {/* Mobile Menu Button */}
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={mobileMenuOpen}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {!mobileMenuOpen ? (
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>

          {/* Logo and Nav Links */}
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center mx-10 lg:pr-16">
            <Link
              to={
                currentUser
                  ? isProvider
                    ? '/partner-home' // Redirect to provider home if they are logged in as a provider
                    : '/client-home'  // Redirect to client home if they are logged in as a client
                  : '/'                // Redirect to default home if not logged in
              }
            >
              <img className="h-20 w-auto " src={vukaLogo} alt="Vuka Logo" />
            </Link>
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex md:space-x-20 space-x-10">
                {!currentUser ? (
                  <>
                    <Link to="/" className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white mt-5">
                      Home
                    </Link>
                    <Menu as="div" className="relative">
                        <Menu.Button className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white flex items-center mt-5">
                          Services
                          <ChevronDownIcon className="w-5 h-5 ml-2" />
                        </Menu.Button>
                        
                        <Menu.Items className="absolute right-0 mt-1 w-96 origin-top-right border border-gray-200 rounded-lg bg-gray-50 shadow-lg dark:bg-gray-800 dark:border-gray-600">
                          <div className="grid max-w-screen-xl p-4">
                            <div className="grid grid-cols-2 gap-4">
                              {categories.map((category) => (
                                <Menu.Item key={category.id}>
                                  {({ active }) => (
                                    <button
                                      onClick={() => handleCategorySelect(category.category)}
                                      className={`block p-3 rounded-lg text-left hover:bg-gray-100 dark:hover:bg-gray-700 ${
                                        active ? 'bg-gray-100 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
                                      }`}
                                    >
                                      <div className="font-semibold text-sm">{category.category}</div>
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </div>
                        </Menu.Items>
                      </Menu>

                    <Link to="/signup" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white mt-5">
                      Sign Up
                    </Link>
                    <Link to="/login" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white mt-5">
                      Log In
                    </Link>
                    {/* <Link to="/about-us" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white mt-5">
                      About Us
                    </Link> */}
                  </>
                ) : isProvider ? (
                  <>
                    <Link to="/partner-home" className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white mt-5 ">
                      Home
                    </Link>
                    <Link to="/partner-accepted-request" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white mt-5">
                      Requests
                    </Link>
                    <Link to="/partner-reviews" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white mt-5">
                      Reviews
                    </Link>

                    {/* <Link to="/pabout" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white mt-5">
                       About Us
                    </Link> */}
                  </>
                ) : (
                  <>
                    <Link to="/client-home" className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white mt-5">
                      Home
                    </Link>
                    <Link to="/show-bookings" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white mt-5">
                      My Bookings
                    </Link>
                    <Menu as="div" className="relative">
                        <Menu.Button className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white flex items-center mt-5">
                          Services
                          <ChevronDownIcon className="w-5 h-5 ml-2" />
                        </Menu.Button>
                        
                        <Menu.Items className="absolute right-0 mt-1 w-96 origin-top-right border border-gray-200 rounded-lg bg-gray-50 shadow-lg dark:bg-gray-800 dark:border-gray-600">
                          <div className="grid max-w-screen-xl p-4">
                            <div className="grid grid-cols-2 gap-4">
                              {categories.map((category) => (
                                <Menu.Item key={category.id}>
                                  {({ active }) => (
                                    <button
                                      onClick={() => handleCategorySelect(category.category)}
                                      className={`block p-3 rounded-lg text-left hover:bg-gray-100 dark:hover:bg-gray-700 ${
                                        active ? 'bg-gray-100 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
                                      }`}
                                    >
                                      <div className="font-semibold text-sm">{category.category}</div>
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </div>
                        </Menu.Items>
                      </Menu>
                    {/* <Link to="/cabout" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white mt-5">
                       About Us
                    </Link> */}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* User Profile Menu */}
          {currentUser && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 ">
              <button
                type="button"
                className='rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white hover:outline-none hover:ring-2 hover:ring-white hover:ring-offset-2 hover:ring-offset-gray-800'
                id="user-menu-button"
                aria-expanded={userMenuOpen}
                onClick={() => setUserMenuOpen(!userMenuOpen)}
              >
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-8 w-8 rounded-full object-cover"
                  src={userData?.profileImageUrl || '/default-profile.png'}
                  alt="Profile"
                />
              </button>

              {userMenuOpen && (
                <div className="absolute right-0 mt-24 mr-10 w-48 origin-top-right bg-white border border-gray-300 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="p-1">
                    <div >
                    <Link onClick={() => setUserMenuOpen(false)}  to={
                       isProvider
                          ? '/partner-user-profile' // Redirect to provider home if they are logged in as a provider
                          : '/client-user-profile'  // Redirect to client home if they are logged in as a client
                                       // Redirect to default home if not logged in
                    }className={`block px-4 py-2  text-sm text-gray-700 hover:bg-gray-100`}>Profile</Link>
                    </div>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
                    >
                      Log out
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="sm:hidden">
          <div className="space-y-1 px-2 pt-2 pb-3">
            {!currentUser ? (
              <>
                <Link to="/" onClick={() => setMobileMenuOpen(false)} className="block rounded-md px-3 py-2 text-base font-medium text-white bg-gray-900">
                  Home
                </Link>
                <Link to="/signup"  onClick={() => setMobileMenuOpen(false)} className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700">
                  Sign Up
                </Link>
                <Link to="/login"  onClick={() => setMobileMenuOpen(false)} className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700">
                  Log In
                </Link>
                <Menu as="div" className="relative">
                        <Menu.Button className="rounded-md px-3 py-2 font-medium text-base text-gray-300 hover:bg-gray-700 hover:text-white flex items-center">
                          Services
                          <ChevronDownIcon className="w-5 h-5 ml-2" />
                        </Menu.Button>
                        
                        <Menu.Items className="absolute right-0 mt-1 w-96 origin-top-right border border-gray-200 rounded-lg bg-gray-50 shadow-lg dark:bg-gray-800 dark:border-gray-600">
                          <div className="grid max-w-screen-xl p-4">
                            <div className="grid grid-cols-2 gap-4">
                              {categories.map((category) => (
                                <Menu.Item key={category.id}>
                                  {({ active }) => (
                                    <button
                                      onClick={() => handleCategorySelect(category.category)}
                                      className={`block p-3 rounded-lg text-left hover:bg-gray-100 dark:hover:bg-gray-700 ${
                                        active ? 'bg-gray-100 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
                                      }`}
                                    >
                                      <div className="font-semibold text-sm">{category.category}</div>
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </div>
                        </Menu.Items>
                      </Menu>

                {/* <Link to="/about-us" className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700">
                  About Us
                </Link> */}
              </>
            ) : isProvider ? (
              <>
                <Link  onClick={() => setMobileMenuOpen(false)} to="/partner-home" className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700">
                  Home
                </Link>
                <Link  onClick={() => setMobileMenuOpen(false)} to="/partner-accepted-request" className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700">
                  Requests
                </Link>
                <Link  onClick={() => setMobileMenuOpen(false)} to="/partner-reviews" className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700">
                  Reviews
                </Link>
                {/* <Link to="/pabout" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
                       About Us
                    </Link> */}
               
              </>
            ) : (
              <>
                <Link to="/client-home"  onClick={() => setMobileMenuOpen(false)} className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700">
                  Home
                </Link>
                <Link to="/show-bookings"  onClick={() => setMobileMenuOpen(false)} className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700">
                  My Bookings
                </Link>
                <Menu as="div" className="relative">
                        <Menu.Button className="rounded-md px-3 py-2 font-medium text-base text-gray-300 hover:bg-gray-700 hover:text-white flex items-center">
                          Services
                          <ChevronDownIcon className="w-5 h-5 ml-2" />
                        </Menu.Button>
                        
                        <Menu.Items className="absolute right-0 mt-1 w-96 origin-top-right border border-gray-200 rounded-lg bg-gray-50 shadow-lg dark:bg-gray-800 dark:border-gray-600">
                          <div className="grid max-w-screen-xl p-4">
                            <div className="grid grid-cols-2 gap-4">
                              {categories.map((category) => (
                                <Menu.Item key={category.id}>
                                  {({ active }) => (
                                    <button
                                      onClick={() => handleCategorySelect(category.category)}
                                      className={`block p-3 rounded-lg text-left hover:bg-gray-100 dark:hover:bg-gray-700 ${
                                        active ? 'bg-gray-100 dark:bg-gray-700' : 'text-gray-700 dark:text-gray-300'
                                      }`}
                                    >
                                      <div className="font-semibold text-sm">{category.category}</div>
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </div>
                        </Menu.Items>
                      </Menu>

                {/* <Link to="/cabout" className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
                       About Us
                </Link> */}
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}
