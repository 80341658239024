import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import Modal from 'react-modal'; // Install react-modal for popup functionality
import axios from 'axios'; // Make sure to install axios
import Swal from 'sweetalert2';
import Unavailable from '../assets/bg-image-background.png';


 


Modal.setAppElement('#root');

// Libraries for Google Maps
const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '250px',
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};


const geocode = async (address) => {
  try {
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: {
        address,
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      }
    });
    const location = response.data.results[0]?.geometry.location;
    return location;
  } catch (error) {
    console.error('Error fetching geocode data:', error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Failed to fetch location data.',
    });
    return null;
  }
};


export default function PartnerHome() {

  const { currentUser } = useAuth();
  const [bookedServices, setBookedServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  // const [clickedServices, setClickedServices] = useState(new Set()); // Track clicked services
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [refreshBookings, setRefreshBookings] = useState(false);
  const [loading, setLoading]  = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    const fetchBookedServices = async () => {
      try {
        const userId = currentUser.uid;
        const q = query(collection(db, 'serviceBookings'), where('providerId', '==', userId));
        const querySnapshot = await getDocs(q);
        const services = querySnapshot.docs
          .map(doc => doc.data())
          .filter(service => service.status === 'Pending') // Filter by status 'Pending'
          .sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate)); // Sort by bookingDate descending
        setBookedServices(services); 
      } catch (error) {
        console.error("Error fetching booked services:", error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to load booked services.',
        });
      }
    };
  
    fetchBookedServices();
  }, [currentUser.uid], refreshBookings);
  

  useEffect(() => {
    const fetchLocation = async () => {
      if (selectedService) {
        const location = await geocode(selectedService.serviceLocation);
        if (location) {
          setMapCenter({ lat: location.lat, lng: location.lng });
        }
      }
    };

    fetchLocation();
  }, [selectedService]);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setModalIsOpen(true);
    // setClickedServices(prev => new Set(prev).add(service.id)); // Track clicked service
  };
  
  const handleAcceptBooking = async (bookingId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true)
          const bookingRef = doc(db, 'serviceBookings', bookingId);
          await updateDoc(bookingRef, { status: 'Accepted' });
          setBookedServices(prevServices =>
            prevServices.map(service =>
              service.bookingId === bookingId ? { ...service, status: 'Accepted' } : service
            )
          );
          setRefreshBookings((prev) => !prev); 
          setLoading(false)
          Swal.fire({
            title: 'Accepted!',
            text: 'The booking has been accepted.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          });
          
          setModalIsOpen(false);
        } catch (error) {
          console.error("Error updating booking status:", error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to accept the booking.',
          });
        }
      }
    });
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return  (
    <div className="flex justify-center items-center h-screen"  role="status">
        <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
    </div>
    )

  return (
    <div className="p-6 relative">
       <div className='2xl:hidden bg-gray-800 h-16 absolute inset-0'></div>
      <h2 className="text-xl font text-white mb-4 text-center relative z-0 2xl:text-black 2xl:font-semibold ">Pending Booked Services</h2>

      {bookedServices.length === 0 ? (
         <div className='flex flex-col items-center justify-center min-h-screen relative z-10'>
         <img src={Unavailable} alt='No Bookings' className='w-3/5 grayscale  opacity-50 lg:w-2/5' />
         <p className='text-3xl sm:text-6xl font-bold text-gray-800 opacity-50 relative z-10'>No Bookings Found</p>
         </div>
      ) : (
        <ul className="divide-y divide-gray-100">
          {bookedServices.map(service => (
            
            <li
              key={service.bookingId}
              className="flex justify-between gap-x-4 py-5 cursor-pointer mx-5 hover:border-2 hover:bg-slate-100 hover:shadow-md rounded-full p-5 transtion ease-in-out duration-100 mt-1 relative z-0 border-gray-200 border-2"
              onClick={() => handleServiceClick(service)}
            >
               <div className='flex min-w-0 gap-x-4'>
              <img src={service.image} alt="Service" className="h-12 w-12 flex-none rounded-full bg-gray-50" />
              <div className="min-w-0 flex-auto">
              <p className="text-md font-semibold leading-6 text-gray-900">{service.clientName}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">Booking Id: {service.bookingId || 'No service specified'}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">Service Requested: {service.providerService|| 'No service specified'}</p>
            </div>
            </div>
            <div className="hidden shrink-0 md:flex md:flex-col md:items-end ">
              <div>
               {/* Dot Indicator */}
            
              <p className="hidden text-sm leading-6 text-gray-900 sm:inline-block">{service.status}</p>
              <div className={`w-3 h-3 rounded-full ml-2 bg-green-500 md:inline-block  ${window.innerWidth >= 640 ? '' : 'absolute mr-7 mt-1 top-2 right-2 '}`}></div>
              </div>
              <p className="text-gray-700">
                <span className="font-semibold text-sm">Service Date: </span>
                {new Date(service.serviceDate).toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
              })}
              </p>
            </div>
           
            </li>
          ))}
        </ul>
      )}

{selectedService && (
  <Modal
    isOpen={modalIsOpen}
    onRequestClose={() => setModalIsOpen(false)}
    className="relative bg-white p-8 rounded-lg shadow-2xl w-full max-w-2xl mx-auto max-h-screen overflow-y-auto"
    overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center"
  >
    { loading && <div className=" fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
          <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>}
    {/* Close button in the top-right corner */}
    <button
      className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
      onClick={() => setModalIsOpen(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">Booking Details</h2>

    <div className="space-y-4 text-sm text-gray-700">
      <p><strong>Booking ID:</strong> {selectedService.bookingId}</p>
      <p><strong>Status:</strong> {selectedService.status}</p>
      <p><strong>Booking Date:</strong> {selectedService.bookingDate}</p>
      <p className="text-gray-700">
                <span className="font-semibold text-sm">Service Date: </span>
                {new Date(selectedService.serviceDate).toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
              })}
              </p>
      <p><strong>Service Location:</strong> {selectedService.serviceLocation}</p>
      <p><strong>Required Service:</strong> {selectedService.providerService}</p>
      <p><strong>Service Description:</strong> {selectedService.serviceDescription || 'No description provided'}</p>
    </div>

    {/* Google Map */}
    <div className="w-full h-64 mb-6 mt-4 overflow-hidden rounded-lg shadow-inner">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={mapCenter}
        options={options}
      >
        <Marker position={mapCenter} />
      </GoogleMap>
    </div>

    {/* Action buttons */}
    <div className="flex justify-end space-x-4">
  <button
    className="px-6 py-3 bg-green-500 text-white font-medium rounded-md shadow hover:bg-green-600 transition duration-200 focus:ring-4 focus:ring-green-300 focus:ring-opacity-50 disabled:bg-gray-400 disabled:cursor-not-allowed"
    onClick={() => handleAcceptBooking(selectedService.bookingId)}
    disabled={selectedService?.status !== 'Pending'}
  >
    Accept Booking Request
  </button>
      <button
        className="px-6 py-3 bg-gray-500 text-white font-medium rounded-md shadow hover:bg-gray-600 transition duration-200 focus:ring-4 focus:ring-gray-300 focus:ring-opacity-50"
        onClick={() => setModalIsOpen(false)}
      >
        Close
      </button>
    </div>
  </Modal>
      )}
    </div>
  );
}
