import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function ProtectedClientRoute({ children }) {
  const { isProvider } = useAuth();

  return isProvider ? children : <Navigate to="/login" />;
}

