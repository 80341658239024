import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../FirebaseConfig';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, getDocs } from 'firebase/firestore';
import {sendPasswordResetEmail} from 'firebase/auth';


const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isProvider, setIsProvider] = useState(false);
  const [userData, setUserData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [providerDetails, setProviderDetails] = useState({
    businessInfo: null,
    contactInfo: null,
    servicesInfo: null,
  });
  // const [verificationId, setVerificationId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setLoading(false);
      if (user) {
        await fetchUserData(user.uid);
      } else {
        setUserData(null);
        setLocations([]);
        setProviderDetails({
          businessInfo: null,
          contactInfo: null,
          servicesInfo: null,
        });
      }
    });

    return unsubscribe;
  }, []);

  const fetchUserData = async (uid) => {
    const db = getFirestore();
    const userDoc = doc(db, 'users', uid);
    const docSnap = await getDoc(userDoc);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setIsProvider(data.isProvider || false);
      setUserData(data);

      // Fetch locations
      const locationCollection = collection(db, `users/${uid}/Locations`);
      const locationSnapshot = await getDocs(locationCollection);
      const locationList = locationSnapshot.docs.map(doc => doc.data());
      setLocations(locationList);

      // Fetch provider details if the user is a provider
      if (data.isProvider) {
        const businessInfoDoc = doc(db, 'users', uid, 'Provider Details', 'Business Info');
        const businessInfoSnap = await getDoc(businessInfoDoc);
        const businessInfo = businessInfoSnap.exists() ? businessInfoSnap.data() : null;

        const contactInfoDoc = doc(db, 'users', uid, 'Provider Details', 'Contact Info');
        const contactInfoSnap = await getDoc(contactInfoDoc);
        const contactInfo = contactInfoSnap.exists() ? contactInfoSnap.data() : null;

        const servicesInfoDoc = doc(db, 'users', uid, 'Provider Details', 'Services Info');
        const servicesInfoSnap = await getDoc(servicesInfoDoc);
        const servicesInfo = servicesInfoSnap.exists() ? servicesInfoSnap.data() : null;

        setProviderDetails({
          businessInfo,
          contactInfo,
          servicesInfo,
        });
      }
    } else {
      setUserData(null);
      setLocations([]);
      setProviderDetails({
        businessInfo: null,
        contactInfo: null,
        servicesInfo: null,
      });
    }
  };

  const refreshUserData = async () => {
    if (currentUser && currentUser.uid) {
      await fetchUserData(currentUser.uid);
    }
  };

  function SignUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  async function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

//   // Initialize Recaptcha for phone number authentication
//   const setUpRecaptcha = () => {
//     window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', auth, {
//       size: 'invisible',
//       callback: (response) => {
//         // reCAPTCHA solved
//       },
//       'expired-callback': () => {
//         // Response expired. Ask user to re-verify.
//       }
//     });
//   };
  

//   // Sign up with phone number
//   const signUpWithPhone = async (phoneNumber) => {
//     setUpRecaptcha(); // Ensure reCAPTCHA is set up first
//     const appVerifier = window.recaptchaVerifier;
    
//     try {
//       const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
//       setVerificationId(confirmationResult.verificationId);
//     } catch (error) {
//       console.error("Error during sign up:", error);
//       throw error; // Propagate the error to be handled in the calling function
//     }
//   };
  

//  // Verify OTP
// const verifyOTP = (verificationCode) => {
//   const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
//   return auth.signInWithCredential(credential);
// };


  async function getIdToken() {
    if (currentUser) {
      return await currentUser.getIdToken();
    }
    return null;
  }

  const value = {
    currentUser,
    login,
    logout,
    SignUp,
    getIdToken,
    resetPassword,
    userData,
    isProvider,
    locations,
    providerDetails,
    refreshUserData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
      {/* Recaptcha container
      <div id="recaptcha-container"></div> */}
    </AuthContext.Provider>
  );
}
