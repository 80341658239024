import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Swal from 'sweetalert2'; 
import { db } from '../FirebaseConfig'; 

export const DataContext = createContext();

export function useData() {
  return useContext(DataContext);
}

export const DataProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState({});
  const [sortedCategories, setSortedCategories] = useState([]);
  const [isServicesLoaded, setIsServicesLoaded] = useState(false);
  // const [bookings, setBookings] = useState([]);
  // const [filteredBookings, setFilteredBookings] = useState([]);
  // const [statusFilter, setStatusFilter] = useState('Pending');

  // Fetch categories from Firestore
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(db, 'Categories'));
        const categoriesList = categoriesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategories(categoriesList);
      } catch (error) {
        console.error("Error fetching categories:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch categories. Please try again later.',
        });
      }
    };

    fetchCategories();
  }, []);

  // Sort categories by service count
  const sortCategoriesByServiceCount = useCallback((servicesData) => {
    const categoryServiceCounts = categories.map((category) => ({
      ...category,
      serviceCount: servicesData[category.category]?.length || 0,
    }));

    const sorted = categoryServiceCounts.sort((a, b) => b.serviceCount - a.serviceCount);
    setSortedCategories(sorted);
  }, [categories]);

  // Fetch services for each category
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesData = {};
        for (const category of categories) {
          const q = query(collection(db, 'AllServices'), where('category', '==', category.category));
          const servicesSnapshot = await getDocs(q);

          const servicesList = servicesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          servicesData[category.category] = servicesList;
        }

        setServices(servicesData);
        sortCategoriesByServiceCount(servicesData);
        setIsServicesLoaded(true);
      } catch (error) {
        console.error("Error fetching services:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch services. Please try again later.',
        });
      }
    };

    if (categories.length > 0) {
      fetchServices();
    }
  }, [categories, sortCategoriesByServiceCount]);

  // New method to get services by category
  const getServicesByCategory = (category) => {
    return services[category] || [];
  };

  // Add this function inside DataProvider
  const fetchProvidersByService = async (service) => {
    if (!service) {
        console.error("No service provided.");
        return [];
    }

    try {
        const q = query(
            collection(db, 'users'),
            where('services_sub_category', 'array-contains', service),
            where('isProvider', '==', true)
        );
        const providersSnapshot = await getDocs(q);

        if (providersSnapshot.empty) {
            console.log("No providers found for service:", service);
            return [];
        }

        return providersSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error("Error fetching providers:", error);
        return [];
    }
};

// // Fetch bookings for the user
// const fetchBookings = useCallback(async () => {
//   if (!userData.id) return;

//   try {
//     const bookingsRef = collection(db, 'serviceBookings');
//     const q = query(bookingsRef, where('userId', '==', userData.id));
//     const querySnapshot = await getDocs(q);

//     if (querySnapshot.empty) {
//       Swal.fire({
//         icon: 'info',
//         title: 'No Bookings',
//         text: 'No bookings found are available.',
//         showConfirmButton: false,
//         timer: 1500,
//       });
//       return;
//     }

//     const fetchedBookings = await Promise.all(
//       querySnapshot.docs.map(async (bookingDoc) => {
//         const bookingData = { id: bookingDoc.id, ...bookingDoc.data() };

//         // Fetch provider info for each booking
//         if (bookingData.providerId) {
//           const providerDocRef = doc(db, 'users', bookingData.providerId);
//           const providerSnapshot = await getDoc(providerDocRef);

//           if (providerSnapshot.exists()) {
//             const providerData = providerSnapshot.data();
//             bookingData.providerImage = providerData.profile_imageUrl || providerData.profileImageUrl;
//           } else {
//             bookingData.providerImage = ''; 
//           }
//         }
//         return bookingData;
//       })
//     );

//     // Sort the fetched bookings by bookingDate in descending order
//     const sortedFetchedBookings = fetchedBookings.sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate));

//     setBookings(sortedFetchedBookings);
//     setFilteredBookings(sortedFetchedBookings.filter(b => b.status === statusFilter));

//   } catch (error) {
//     Swal.fire({
//       icon: 'error',
//       title: 'Error',
//       text: 'Error fetching bookings. Please try again later.',
//     });
//   }
// }, [userData.id, statusFilter]);

// useEffect(() => {
//   fetchBookings();
// }, [fetchBookings]);

  return (
    <DataContext.Provider
      value={{
        categories,
        sortedCategories,
        services,
        isServicesLoaded,
        getServicesByCategory, // Expose the new method
        fetchProvidersByService,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
