import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { db, storage } from '../FirebaseConfig';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import NotFound from '../assets/not-found-removebg-preview.png';


function PartnerUserProfile() {
  const { userData, currentUser, refreshUserData } = useAuth();
  const [profileData, setProfileData] = useState(null); // Initialize as null to handle loading state
  const [isLoading, setIsLoading] = useState(true); // Track loading state for profile data
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (userData) {
      setProfileData(userData); // Only set profile data if userData is available
      setIsLoading(false); // Set loading to false after data is set
    }
  }, [userData]);

  const handleImageUpload = useCallback(async (file) => {
    try {
      setLoading2(true);
      if (file) {
        const imageRef = ref(storage, `profileImages/${currentUser.uid}/${file.name}_${uuidv4()}`);
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);

        await updateDoc(doc(db, 'users', currentUser.uid), { profileImageUrl: url });
        setProfileData((prev) => ({ ...prev, profileImageUrl: url }));
        refreshUserData();

        setLoading2(false);

        Swal.fire({
          title: 'Image Uploaded',
          text: 'Your profile image has been updated successfully!',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
      }
    } catch (error) {
      setLoading2(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong with the image upload!',
        footer: error.message
      });
    }
  }, [currentUser, refreshUserData]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await handleImageUpload(file); // Upload and display image immediately
    }
  };

  const handleToggleModal = () => {
    setIsOpen(!isOpen);
};



  if (!profileData) {
    // Handle case where profile data is still not available
    return  <div className='flex flex-col items-center justify-center min-h-screen relative z-10'>
    <img src={NotFound} alt='No Bookings' className='w-3/5 grayscale  opacity-50 lg:w-2/5' />
    </div>
  }

  if (isLoading) return(
    <div className="flex justify-center items-center h-screen bg-black opacity-50"  role="status">
        <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
    </div> )

  return (
    <div className=" mx-auto bg-white p-8 shadow-lg relative">
      <div
                className="absolute top-4 left-4 bg-green-500 text-white p-1 rounded-full flex items-center justify-center cursor-pointer transform translate-y-0 hover:translate-y-1 transition-transform duration-300"
                onClick={handleToggleModal}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8v4m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
                    />
                </svg>
            </div>

            {/* Modal */}
            {isOpen && (
                <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 w-80 shadow-lg">
                        <h2 className="text-lg font-bold mb-4">Payment Information</h2>
                        <p><strong>PayBill:</strong> 516600</p>
                        <p><strong>Account Number:</strong> 133113</p>
                        <p><strong>Account Name:</strong> Dualmax</p>
                        <button
                            className="mt-4 px-4 py-2 bg-green-500 text-white rounded"
                            onClick={handleToggleModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
    <div className="divide-y divide-gray-300">
      <div className="py-8">
        <h3 className="text-2xl leading-6 font-bold text-gray-900">Profile</h3>
        <p className="mt-2 max-w-2xl text-sm text-gray-500">
          Information about your account
        </p>
      </div>
  
      <div className="pt-8 grid grid-cols-1 sm:grid-cols-2 gap-6">
        {/* Profile Picture */}
        <div className="flex flex-col items-center sm:mt-16 ">
        
          <label htmlFor="photo" className="block text-xl font-medium text-gray-700">
            Profile Picture
          </label>
          <div className="mt-4 relative">

          { loading2 && <div className=" absolute inset-0 w-full h-full rounded-full flex items-center justify-center bg-black bg-opacity-50 z-50 ">
          <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>}
            <span className="inline-block sm:h-72 sm:w-72 h-32 w-32 rounded-full overflow-hidden bg-gray-100 shadow-lg cursor-pointer">
              <img
                className="h-full w-full object-cover"
                src={profileData.profileImageUrl || '/default-profile.png'}
                alt="Profile"
                onClick={() => setImageModalOpen(true)} 
              />
            </span>
          </div>
          <button
            type="button"
            className="mt-4 bg-indigo-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => document.getElementById('photo-upload').click()}
          >
            Change
          </button>
          <input
            id="photo-upload"
            type="file"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>
        {/* Larger Image Modal */}
    {imageModalOpen && (
      <Modal
        isOpen={imageModalOpen}
        onRequestClose={() => setImageModalOpen(false)}
        className="relative p-4 rounded-full shadow-lg w-auto max-w-xl mx-auto my-auto h-auto "
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center"
      >
        <button
          className="absolute top-3 right-3 z-20 text-gray-500 hover:text-gray-700"
          onClick={() => setImageModalOpen(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        
        <img
          src={profileData.profileImageUrl}
          alt="Client Profile Full"
          className=" h-full w-full object-cover"
        />
      </Modal>
    )}
  
        {/* Profile Details */}
        <div>
          <div className="mb-4">
            <p className="font-semibold text-gray-700">Name:</p>
            <p className="text-gray-600">{profileData.name}</p>
          </div>
          <div className="mb-4">
            <p className="font-semibold text-gray-700">Email:</p>
            <p className="text-gray-600">{profileData.email}</p>
          </div>
          <div className="mb-4">
            <p className="font-semibold text-gray-700">Phone:</p>
            <p className="text-gray-600">{profileData.phone}</p>
          </div>
          <div className="mb-4">
            <p className="font-semibold text-gray-700">Gender:</p>
            <p className="text-gray-600">{profileData.gender}</p>
          </div>
          <div className="mb-4">
            <p className="font-semibold text-gray-700">Rating:</p>
            <div className="flex items-center">
              {[...Array(5)].map((_, i) => (
                <svg
                  key={i}
                  className={`w-6 h-6 ${i < profileData.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                </svg>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <p className="font-semibold text-gray-700">Services Category:</p>
            <p className="text-gray-600">{profileData.services_category}</p>
          </div>
          <div className="mb-4">
            <p className="font-semibold text-gray-700">Services Sub Category:</p>
            <div className="mt-2 flex flex-wrap gap-2">
              {profileData.services_sub_category.map((subcategory, index) => (
                <span
                  key={index}
                  className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full text-sm font-medium shadow-sm"
                >
                  {subcategory}
                </span>
              ))}
            </div>
          </div>
          <div>
            <p className="font-semibold text-gray-700">Country:</p>
            <p className="text-gray-600">{profileData.user_country}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default PartnerUserProfile;

