import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useData } from '../contexts/DataContext';
import Swal from 'sweetalert2';
import Unavailable from '../assets/bg-image-background.png';

export default function DisplayServices() {
  const { category } = useParams();
  const { getServicesByCategory } = useData();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        setLoading(true);
        setError(null);

        // Check if getServicesByCategory is available
        if (!getServicesByCategory) {
          setError('Unable to fetch services. The function is unavailable.');
          setLoading(false);
          return;
        }

        const fetchedServices = await getServicesByCategory(category);

        if (!fetchedServices || fetchedServices.length === 0) {
          Swal.fire({
            icon: 'info',
            title: 'No Services Found',
            text: `There are no services available for the category: ${category}.`,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate('/');
        } else {
          setServices(fetchedServices);
        }
      } catch (error) {
        console.error('Error fetching services:', error);
        setError('Failed to fetch services. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [category, getServicesByCategory, navigate]);

  const handleViewProviders = (service) => {
    const encodedService = encodeURIComponent(service);
    navigate(`/service-providers/${encodedService}`);
  };
  

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen" role="status">
        <svg
          aria-hidden="true"
          className="text-gray-200 animate-spin dark:text-gray-600 fill-purple-800 w-14 h-14"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-500">Error: {error}</div>;
  }

  if (!services || services.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen relative z-10">
        <img src={Unavailable} alt="No Services Found" className="w-3/5 grayscale opacity-50 lg:w-2/5" />
        <p className="mt-5 text-lg text-gray-600">No services available for the category: {category}</p>
      </div>
    );
  }

  return (
    <div className='relative'>
       <div className='2xl:hidden bg-gray-800 h-52 absolute inset-0'></div>
      <h2 className='font text-2xl p-5 text-center relative z-10 text-white 2xl:text-black 2xl:font-semibold '>{category} Services</h2>
      {services.length === 0 ? (
         <div className='flex flex-col items-center justify-center min-h-screen relative z-10'>
         <img src={Unavailable} alt='No Bookings' className='w-3/5 grayscale  opacity-50 lg:w-2/5' />
         <p className='text-3xl sm:text-6xl font-bold text-gray-800 opacity-50'>No Bookings Found</p>
         </div>
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:mx-3 mx-14'>
          {services.map((service) => (
            <div
              onClick={() => handleViewProviders(service.services)}
              key={service.id}
              className='bg-white rounded overflow-hidden shadow-md hover:bg-gray-100 hover:shadow-lg cursor-pointer transform hover:-translate-y-0.1 hover:translate-x-0.1 hover:scale-105 transition ease-in-out duration-300 '
            >
              <img src={service.imageUrl} alt={service.name} className='w-full object-cover h-32 sm:h-48' />
              <p className='font-bold m-4'>{service.services}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
