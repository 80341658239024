import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import { storage } from '../../FirebaseConfig';
import { useAuth } from '../../contexts/AuthContext';
import Swal from 'sweetalert2'; // SweetAlert2 for notifications
import DefaultProfilePhoto from '../../assets/DefaultProfilePhoto-removebg-preview.jpg';
import VukaLogo from '../../assets/fulllogo-removebg-preview.svg';


  // Set profile image to DefaultProfileImage and upload it to Firebase Storage
  export default function SignUp() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [roleRef,setRoleRef] = useState('');
    const nameRef = useRef();
    const phoneRef = useRef();
    const genderRef = useRef();
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const { SignUp } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showClientPopover, setShowClientPopover] = useState(false);
    const [showProviderPopover, setShowProviderPopover] = useState(false);
    const [registeredDate, setRegisteredDate] = useState('');
  
    // Set profile image to DefaultProfileImage and upload it to Firebase Storage
    const handleImageUpload = useCallback(async () => {
      try {
        // Set the default profile image if no image is uploaded
        const imageRef = ref(storage, `profileImages/DefaultProfileImage`);
        const response = await fetch(DefaultProfilePhoto); // Fetch the default image file
        const blob = await response.blob(); // Convert it into a blob
        await uploadBytes(imageRef, blob); // Upload the default image blob to Firebase Storage
        const url = await getDownloadURL(imageRef); // Get the download URL of the uploaded image
        setProfileImageUrl(url); // Set the uploaded image URL as profile image
  
      } catch (err) {
        console.error('Error uploading image:', err);
        Swal.fire({
          icon: 'error',
          title: 'Image upload failed',
          text: 'Please try again.',
        });
      }
    }, []);
  
    // Upload default image on component mount
    useEffect(() => {
      handleImageUpload();
    }, [handleImageUpload]);

    const handleRoleChange = (event) => {
      setRoleRef(event.target.value);
    };

    // Function to get the formatted date
    const getFormattedDate = () => {
      const date = new Date();
  
      // Get the date in the desired format
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const day = String(date.getDate()).padStart(2, '0');
      const hours = date.getHours() % 12 || 12; // Convert 24-hour to 12-hour format
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  
      // Combine them into the desired format
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
      
      setRegisteredDate(formattedDate);
    };

  // Trigger date formatting when the component loads
  useEffect(() => {
    getFormattedDate();
  }, []);

  
     // Registration handler
     const handleNext = async (e) => {
      e.preventDefault();
    
      try {
        setError('');
        setLoading(true);
    
        if (!profileImageUrl) {
          setError('Profile image upload failed');
          Swal.fire({
            icon: 'error',
            title: 'Profile image missing',
            text: 'Please upload a profile image to continue.',
          });
          return;
        }
    
        // Create user in Firebase Auth
        const userCredential = await SignUp(emailRef.current.value, passwordRef.current.value);
        const user = userCredential.user;
    
        // Save user's role in their profile using updateProfile
        await updateProfile(user, {
          displayName: roleRef,
        });
    
        const isProvider = roleRef === 'provider';
    
        const userData = {
          id: user.uid,
          email: emailRef.current.value,
          name: nameRef.current.value,
          phone: phoneRef.current.value, // Ensure this is in E.164 format
          gender: genderRef.current.value,
          profileImageUrl: profileImageUrl,
          appOwner: 'NotOwner',
          deviceToken: "",
          isProvider: isProvider,
          isVerified: false,
          jobs_done: 0,
          rating: 0,
          regDate: registeredDate,
          services_category: "",
          status: "NotVerified",
          subscribed_services: 0,
          totalUserRated: 0,
          userRole: "user",
          user_country: "Kenya",
        };
    
        // Redirect to ValidatePhone.js
        Swal.fire({
          icon: 'success',
          title: 'Account created!',
          text: 'Redirecting to verify your phone number...',
          showConfirmButton: false,
          timer: 2000,
        });
    
        navigate('/location', { state: { userData } }); // Redirect to OTP verification page
      } catch (err) {
        console.error('Error during sign-up process:', err);
        setError('Failed to create account');
        Swal.fire({
          icon: 'error',
          title: `Registration failed `,
          text: `Please check your information and try again.${err}`,
        });
      } finally {
        setLoading(false);
      }
    };
    

  // Validate input fields
  
    // Name validation only contains letters and space, not number
    const validateName = () => {
      if (!nameRef.current.value ) {
        nameRef.current.setCustomValidity('Please enter your full name.');
        nameRef.current.classList.add('border-red-500','border-2'); // Add red border for invalid input
      } else if(!nameRef.current.value.match(/^[a-zA-Z\s]*$/)){
        nameRef.current.setCustomValidity('Please do not include numbers or special characters.');
        nameRef.current.classList.add('border-red-500','border-2');
      } else {
        nameRef.current.setCustomValidity('');
        nameRef.current.classList.remove('border-red-500','border-2');
      }
      nameRef.current.reportValidity();
    }
    

    // Email validation
    const validateEmail = () => {
      if (!emailRef.current.value.includes('@')) {
        emailRef.current.setCustomValidity('Please enter a valid email address.');
        emailRef.current.classList.add('border-red-500', 'border-2');
      } else {
        emailRef.current.setCustomValidity('');
        emailRef.current.classList.remove('border-red-500', 'border-2');
      }
      emailRef.current.reportValidity();
    }
   

    // Password validation
    const validatePassword = () => {
      if (passwordRef.current.value.length < 6) {
        passwordRef.current.setCustomValidity('Password must be at least 6 characters long.');
        passwordRef.current.classList.add('border-red-500', 'border-2');
      } else {
        passwordRef.current.setCustomValidity('');
        passwordRef.current.classList.remove('border-red-500', 'border-2');
      }
      passwordRef.current.reportValidity();
  
    }
    
    // Phone number validation where phone number should start with +254 
    const validatePhonenumber = () => {
      if (!phoneRef.current.value) {
        phoneRef.current.setCustomValidity('Please enter a phone number.');
        phoneRef.current.classList.add('border-red-500', 'border-2');
      } 
      // phone number should start with +254
      // else if (!phoneRef.current.value.match(/^\+254\d{}$/)){
      //   phoneRef.current.setCustomValidity('Phone number should start with +254 and have 12 digits.');
      //   phoneRef.current.classList.add('border-red-500', 'border-2');
      // }

       else {
        phoneRef.current.setCustomValidity('');
        phoneRef.current.classList.remove('border-red-500', 'border-2');
      }
      phoneRef.current.reportValidity();
    }
   

    // Gender validation
    const validateGender = () => {
      if (!genderRef.current.value) {
        genderRef.current.setCustomValidity('Please select your gender.');
        genderRef.current.classList.add('border-red-500', 'border-2');
      } else {
        genderRef.current.setCustomValidity('');
        genderRef.current.classList.remove('border-red-500', 'border-2');
      }
      genderRef.current.reportValidity();
    }
   

    return (
        <div className="min-h-screen flex">
        <div className="flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 w-full sm:w-1/2 bg-white">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            {error && <p className="text-red-500">{error}</p>}
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 ">
              Your Best Work Starts Here
            </h2>
            <form onSubmit={handleNext} className="mt-8 space-y-6">
            { loading && <div className=" fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
          <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>}
              <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                    </svg>
                  </span>
                  <input type="text" id="name" ref={nameRef} required onInput={validateName}className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Full Name" />
                </div>
              </div>
                <div>
                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-3">Your Email</label>
                    <div className="relative mb-6">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                        </svg>
                      </div>
                      <input type="email" id="email" ref={emailRef}  onInput={validateEmail}required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@example.com"/>
                    </div>
                </div>
                <div>
                <div>
                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-3">Your Password</label>
                    <div className="relative mb-6">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 text-gray-400 dark:text-gray-400" aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
                      </svg>

                      </div>
                      <input type="password" id="password" ref={passwordRef}  onInput={validatePassword} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Password"/>
                    </div>
                </div>
                </div>
                <div>
                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-3">Phone Number</label>
                    <div className="relative mb-6">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                      </svg>
                      </div>
                      <input type="number" id="phone" ref={phoneRef}  onInput={validatePhonenumber} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " placeholder="+2547XXXXXXXX"/>
                    </div>

                </div>
                <div>
                <p className='block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-3'>Gender</p>
                <label htmlFor="gender" className="sr-only">
                    Gender
                  </label>
                  <select
                    id="gender"
                    type="text"
                    onInput={validateGender}
                    ref={genderRef}
                    required
                    className="block w-full px-5 py-2.5 text-white  bg-purple-800 hover:bg-purple-950 border-blue-700 rounded-lg f dark:bg-blue-600 dark:border-blue-600 dark:hover:bg-blue-700 "
                  >
                    <option value="" className="text-gray-900">Select Gender</option>
                    <option value="Male" className="text-gray-900">Male</option>
                    <option value="Female" className="text-gray-900">Female</option>
                    <option value="Other" className="text-gray-900">Other</option>
                  </select>

                </div>
                <div className='block mb-2 text-sm font-medium text-gray-900 py-3'>
                Client or Provider
              </div>
              <div className="flex justify-center space-x-20 sm:space-x-0 md:space-x-20">
                <div 
                  className="flex items-center ps-4 border border-gray-200 rounded pr-7 mr-12 relative"
                  onMouseEnter={() => setShowClientPopover(true)}
                  onMouseLeave={() => setShowClientPopover(false)}
                >
                  <input
                    id="bordered-radio-client"
                    type="radio"
                    value="client"
                    name="role"
                    required
                    
                    onClick={ handleRoleChange  }
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                  />
                  <label
                    htmlFor="bordered-radio-client"
                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900"
                  >
                    Client
                  </label>
                  

                  {/* Client Popover */}
                  {showClientPopover && (
                    <div
                      className={`absolute z-10 inline-block w-64 text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm p-2 left-10 bottom-10 transition-opacity duration-300 ${
                        showClientPopover ? 'opacity-100 visible' : 'opacity-0 invisible'
                      } dark:text-gray-400 dark:border-gray-800`}
                    >
                      <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                        <h3 className="font-semibold text-gray-900 dark:text-white">
                          Client.
                        </h3>
                      </div>
                      <div className="px-3 py-2">
                        <p>Order a service from a provider of your choice</p>
                      </div>
                      <div data-popper-arrow></div>
                    </div>
                  )}

                </div>
                <div 
                  className="flex items-center ps-4 border border-gray-200 rounded pr-7 relative"
                  onMouseEnter={() => setShowProviderPopover(true)}
                  onMouseLeave={() => setShowProviderPopover(false)}
                >
                  <input
                    id="bordered-radio-provider"
                    type="radio"
                    value="provider"
                    name="role"
                    onClick={handleRoleChange }
                    required
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                  />
                  <label
                    htmlFor="bordered-radio-provider"
                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900"
                  >
                    Partner
                  </label>

                  {/* Provider Popover */}
                  {showProviderPopover && (
                    <div
                    className={`absolute z-10 inline-block w-64 text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm p-2 -left-40 sm:left-10 bottom-10 transition-opacity duration-300 ${
                      showProviderPopover ? 'opacity-100 visible' : 'opacity-0 invisible'
                    } dark:text-gray-400 dark:border-gray-800`}
                  >
                    <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                      <h3 className="font-semibold text-gray-900 dark:text-white">
                        Partner.
                      </h3>
                    </div>
                    <div className="px-3 py-2">
                      <p>Become a partner and work with us.</p>
                    </div>
                    <div data-popper-arrow></div>
                  </div>
                  )}
                </div>
              </div>


          
                {/* <div>
                  <p className='block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-4'> Client or Provider</p>
                  <label htmlFor="role" className="sr-only">
                    Role
                  </label>
                  <select
                    id="role"
                    ref={roleRef}
                    required
                    className=" block w-full px-5 py-2.5 text-white  bg-indigo-600 hover:bg-indigo-700 border-blue-700 rounded-lg f dark:bg-blue-600 dark:border-blue-600 dark:hover:bg-blue-700 "
                  >
                    <option value="client" className="text-gray-900">Client</option>
                    <option value="provider" className="text-gray-900">Provider</option>
                  </select>
                </div> */}
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  required
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
               <label className="ml-2 block text-sm text-gray-900">
                By signing up, you agree to our{' '}
                <a 
                  href="https://sicons-cooling-limited.web.app/terms.html" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Terms of Use and Privacy Policy
                </a>.
              </label>

              </div>
              <div>
                <button
                  disabled={loading}
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-800 hover:bg-purple-950 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create an account
                </button>
              </div>
              <div className="text-sm text-center">
                Already have an account?{" "}
                <a href="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Login here
                </a>
              </div>
            </form>
          </div>
        </div>
        <div className="hidden sm:flex w-1/2 bg-gray-800 items-center justify-center p-8 pb-40 xl:pb-56">
          <div className="text-white text-center">
          <img src={VukaLogo} alt="Vuka Logo" className='w-auto h-auto 2xl:ml-5' />
            <h1 className="text-3xl lg:text-4xl xl:text-5xl lg:font-semibold lg:text-wrap  font-bold ">Find the Service you are Looking for.</h1>
            <p className="mt-4 lg:text-xl xl:text-2xl py-3 xl:py-8">Start now and get reliable work done for you.</p>
            <div className=" flex items-center justify-center relative my-8">
              {/* You can replace with real user images */}
              <div className="w-10 h-10 rounded-full   absolute z-30 mr-16 ">
                <img src="https://images.unsplash.com/photo-1493106819501-66d381c466f1?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGZhY2V8ZW58MHx8MHx8fDA%3D" alt="User" className="w-full h-full rounded-full object-cover" />
              </div>
              <div className="w-10 h-10 rounded-full bg-white absolute z-20  ">
              <img src="https://flowbite.com/docs/images/people/profile-picture-2.jpg"alt="User" className="w-full h-full rounded-full object" />
              </div>
              <div className="w-10 h-10 rounded-full bg-white absolute z-10  ml-16">
              <img src="https://flowbite.com/docs/images/people/profile-picture-4.jpg"alt="User" className="w-full h-full rounded-full object" />
              </div>
              <div className="w-10 h-10 rounded-full bg-white absolute z-0  ml-32">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9rN9M5gE8VezuZ9wscDTJJmcRWAdULYGFOw&s"alt="User" className="w-full h-full rounded-full object" />
              </div>
            </div>
            <p className="mt-4 lg:text-xl xl:text-2xl">Find out whose next to you!</p>
          </div>
        </div>
      </div>
      
    );
}
