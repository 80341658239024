import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignUp from './components/Auth/SignUp';
import Login from './components/Auth/Login';
import ClientHome from './Clientpages/ClientHome';
import PartnerHome from './0PartnerPages/PartnerHome';
import ProtectedClientRoute from './components/ProtectedClientRoute';
import ProtectedProviderRoute from './components/ProtectedProviderRoute';
import Home from './Generalpages/Home';
import Layout from './layout/Layout';
import DisplayServices from './Generalpages/DisplayServices';
import Location from './components/Auth/Location';
import ProviderDetails from './components/Auth/ProviderDetails';
import ShowServiceProvider from './Generalpages/ShowServiceProvider';
import ClientShowServiceProvider from './Clientpages/ClientShowServiceProvider';
import RequestService from './Clientpages/RequestService';
import ServiceLocation from './Clientpages/ServiceLocation';
import ClientDisplayServices from './Clientpages/ClientDisplayServices';
// import AboutUs from './Generalpages/AboutUs';
// import ClientAboutUs from './Clientpages/ClientAboutUs';
// import PartnerAboutUS from './0PartnerPages/PartnerAboutUS';
import ShowBookings from './Clientpages/ShowBookings';
import PartnerAcceptedRequest from './0PartnerPages/PartnerAccetpedRequest';
import ClientUserProfile from './Clientpages/ClientUserProfile';
import PartnerUserProfile from './0PartnerPages/PartnerUserProfile';
import ParnterReviews from './0PartnerPages/PartnerReviews';
import 'flowbite/dist/flowbite.min.css';
// import ValidatePhone from './components/Auth/ValidatePhone';


export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<Home/>}/>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/location" element={<Location />} />
          <Route path="/provider-details" element={<ProviderDetails />} />
          <Route path="/display-services/:category" element={<DisplayServices />} />
          {/* <Route path="/about-us" element={<AboutUs />} /> */}
          <Route path="/service-providers/:service" element={<ShowServiceProvider />} />
          {/* <Route path='/vphone' element= {<ValidatePhone/>}/> */}

          <Route
            path="/client-home"
            element={
              <ProtectedClientRoute>
                <ClientHome />
              </ProtectedClientRoute>
            }
          />
          {/* < Route 
              path='/client-about-us'
              element={
                <ProtectedClientRoute>
                  <ClientAboutUs />
                </ProtectedClientRoute>
              } 
            /> */}

          < Route 
              path='/client-user-profile'
              element={
                <ProtectedClientRoute>
                  <ClientUserProfile />
                </ProtectedClientRoute>
              }
            />

         

          {/* < Route
            path='/pabout'
            element={
              <ProtectedClientRoute>
                <PartnerAboutUS/>
              </ProtectedClientRoute>
            }
          /> */}

          {/* < Route
            path='/cabout'
            element={
              <ProtectedClientRoute>
                <ClientAboutUs />
              </ProtectedClientRoute>
            }
          /> */}


        

          < Route 
              path='/client-display-services/:category'
              element={
                <ProtectedClientRoute>
                  <ClientDisplayServices />
                </ProtectedClientRoute>
              }
            />

          < Route 
              path='/show-bookings'
              element={
                <ProtectedClientRoute>
                  <ShowBookings />
                </ProtectedClientRoute>
              }
            />
          
          <Route
            path="/client-service-provider/:service"
            element={
              <ProtectedClientRoute>
                <ClientShowServiceProvider />
              </ProtectedClientRoute>
            }
          />
          <Route 
              path='/request-service'
              element={
                <ProtectedClientRoute>
                  <RequestService />
                </ProtectedClientRoute>
              }
            />
           <Route 
              path='/service-location'
              element={
                <ProtectedClientRoute>
                  <ServiceLocation />
                </ProtectedClientRoute>
              }
            /> 
          <Route
            path="/partner-home"
            element={
              <ProtectedProviderRoute>
                <PartnerHome />
              </ProtectedProviderRoute>
            }
          />

          < Route 
              path='/partner-user-profile'
              element={
                <ProtectedProviderRoute>
                  <PartnerUserProfile />
                </ProtectedProviderRoute>
              }
            />

          < Route 
              path='/partner-accepted-request'
              element={
                <ProtectedProviderRoute>
                  <PartnerAcceptedRequest />
                </ProtectedProviderRoute>
              }
            />

          < Route
            path='/partner-reviews'
            element={
              <ProtectedProviderRoute>
                <ParnterReviews/>
              </ProtectedProviderRoute>
            }
          />
        </Route> 
      </Routes>
    </Router>
  );
}
