import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, setDoc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db, storage } from '../../FirebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Swal from 'sweetalert2'; // Import SweetAlert2
import VukaLogo from '../../assets/fulllogo-removebg-preview.svg';
import { useAuth } from '../../contexts/AuthContext';



export default function ProviderDetails() {
    const { refreshUserData } = useAuth();
    const [businessInfo, setBusinessInfo] = useState({
        business_id: '',
        idNumber: '',
        idType: '',
        imageUrls: [],
        regDate: new Date().toLocaleString(),
    });
    const [contactInfo, setContactInfo] = useState({});

    const [servicesInfo, setServicesInfo] = useState({
        service_id: '',
        services_category: '',
        services_sub_category: [], // Tracking selected subcategories (services)
        regDate: new Date().toLocaleString(),
    });
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false)
    const [services, setServices] = useState([]);
    const { state } = useLocation();
    const navigate = useNavigate();
    const idRef = useRef()
    const idTypeRef = useRef()
    const [registeredDate, setRegisteredDate] = useState('');

    // Function to get the formatted date
    const getFormattedDate = () => {
        const date = new Date();
    
        // Get the date in the desired format
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = date.getHours() % 12 || 12; // Convert 24-hour to 12-hour format
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    
        // Combine them into the desired format
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
        
        setRegisteredDate(formattedDate);
      };
  
    // Trigger date formatting when the component loads
    useEffect(() => {
      getFormattedDate();
    }, []);

    useEffect(() => {
        const LocationInfo = state?.selectedLocation || {};
        const userData = state?.userData || {};   
        if (userData) {
            setContactInfo(prev => ({
                ...prev,
                Name: userData.name || '',
                Email: userData.email || '',
                phone: userData.phone || '',
                street: LocationInfo.street || '',
                regDate: new Date().toLocaleString(),
                job_completed: 0,
                provider_ratings: 0,
                work_profile: userData.profileImageUrl||'',
            }));
        }

        const loadCategories = async () => {
            try {
                const categoryCollection = collection(db, 'Categories');
                const categorySnapshot = await getDocs(categoryCollection);
                const fetchedCategories = categorySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCategories(fetchedCategories);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };
        loadCategories();
    }, [state]);

    const handleCategoryChange = async (e) => {
        const selectedCategory = e.target.value;
    
        setServicesInfo(prev => ({
            ...prev,
            services_category: selectedCategory,
            services_sub_category: [] // Reset subcategories when a new category is selected
        }));
    
        if (selectedCategory) {
            try {
                const servicesCollection = collection(db, 'AllServices');
                const q = query(servicesCollection, where('category', '==', selectedCategory));
                const servicesSnapshot = await getDocs(q);
                const fetchedServices = servicesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
    
                setServices(fetchedServices); 
            
                
            } catch (error) {
                console.error("Error fetching services:", error);
            }
        }
       
    };

    const handleSubCategoryChange = (e) => {
        const { value, checked } = e.target;
        setServicesInfo(prev => {
            const updatedSubCategories = checked
                ? [...prev.services_sub_category, value] // Add selected subcategory
                : prev.services_sub_category.filter(subCat => subCat !== value); // Remove unselected subcategory
            return {
                ...prev,
                services_sub_category: updatedSubCategories,
            };
        });
    };

   // Handle file upload
   const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files); // Get files from input

    // Create upload tasks
    const uploadTasks = files.map(file => {
        const fileRef = ref(storage, `businessImages/${file.name}`); // Reference to the Firebase storage
        return uploadBytes(fileRef, file).then(() => getDownloadURL(fileRef));
    });

    // Get URLs of uploaded files
    const urls = await Promise.all(uploadTasks);

    // Update state with new image URLs
    setBusinessInfo(prev => ({
        ...prev,
        imageUrls: [...prev.imageUrls, ...urls],
    }));
};

    const handleSignUp = async () => {
        try {
            const userId = state?.userId || '';
            if (!userId) {
                console.error('User ID is not defined');
                Swal.fire({
                    icon: 'error',
                    title: 'User ID Error',
                    text: 'User ID is not defined',
                });
                return;
            }
            setLoading(true);

            const businessDocRef = doc(db, 'users', userId, 'Provider Details', 'Business Info');
            const contactDocRef = doc(db, 'users', userId, 'Provider Details', 'Contact Info');
            const servicesDocRef = doc(db, 'users', userId, 'Provider Details', 'Services Info');
            const userDocRef = doc(db, 'users', userId);

            await setDoc(businessDocRef, {
                ...businessInfo,
                business_id: businessDocRef.id,
                regDate: registeredDate,
            });

            await setDoc(contactDocRef, {
                ...contactInfo,
                contact_id: contactDocRef.id,
                regDate: registeredDate,
            });

            await setDoc(servicesDocRef, {
                ...servicesInfo,
                service_id: servicesDocRef.id,
                regDate: registeredDate,
            });

            await updateDoc(userDocRef, {
                services_category: servicesInfo.services_category,
                services_sub_category: servicesInfo.services_sub_category,
                subscribed_services: servicesInfo.services_sub_category.length, // Count selected subcategories
            });
            setLoading(false)

            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Your provider details have been successfully saved.',
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                navigate('/partner-home');
                refreshUserData();
            });

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Error saving provider details: ${error.message}`,
            });
            console.error('Error saving provider details:', error);
            setLoading(false)
        }
    };

    // validating that the id number the user provides is not less than 4 characters
    const ValidateIdNumber = () => {
        if (idRef.current.value.length < 4) {
            idRef.current.setCustomValidity('ID number must be at least 4 characters long.');
            idRef.current.classList.add('border-red-500', 'border-2');
        } else {
            idRef.current.setCustomValidity('');
            idRef.current.classList.remove('border-red-500', 'border-2');
        }
    }

    // Validating id type
    const ValidateIdType = () => {
        if (!idTypeRef.current.value) {
            idTypeRef.current.setCustomValidity('Please select an ID type.');
            idTypeRef.current.classList.add('border-red-500', 'border-2');
        } else {
            idTypeRef.current.setCustomValidity('');
            idTypeRef.current.classList.remove('border-red-500', 'border-2');
        }
    }



    return (
        <div className='min-h-screen flex'>
             { loading && <div className=" fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
          <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
      </div>}
            <div className='flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 w-full sm:w-1/2 bg-white'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                    <h2 className='mt-2 text-3xl font-extrabold text-gray-900 '>Enter Your Personal Details</h2>

                    <h2 className='mt-6 text-xl font-semibold text-gray-900 border-b-2 inline-block'>Identification Information</h2>
                    
                    <div className="mt-6 flex space-x-4">
                        <div className="w-1/2">
                            <label htmlFor="idNumber" className="block mb-2 text-sm font-medium text-gray-900">Identification Number</label>
                            <input
                                type="text"
                                id="idNumber"
                                value={businessInfo.idNumber}
                                ref = {idRef}
                                onInput={ValidateIdNumber}
                                onChange={e => setBusinessInfo(prev => ({ ...prev, idNumber: e.target.value }))}
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="ID Number"
                            />
                        </div>
                        <div className="w-1/2">
                            <label htmlFor="idType" className="block mb-2 text-sm font-medium text-gray-900">ID Type</label>
                            <select
                                value={businessInfo.idType}
                                ref = {idTypeRef}
                                onChange={e => setBusinessInfo(prev => ({ ...prev, idType: e.target.value }))}
                                required
                                onInput={ValidateIdType}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            >
                                <option value="">Select ID Type</option>
                                <option value="National Identity Card (ID)">National Identity Card (ID)</option>
                                <option value="Passport">Passport</option>
                                <option value="Drivers License">Drivers License</option>
                            </select>
                        </div>
                    </div>

                    <div className="mt-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Upload Back of ID</label>
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                required
                                onChange={e => handleFileUpload(e, 'businessImages0')}
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none ml-3"
                            />
                        </div>

                        <div className="mt-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Upload Front of ID</label>
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                required
                                onChange={e => handleFileUpload(e, 'businessImages1')}
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none ml-3"
                            />
                        </div>

                        <h2 className='mt-6 text-xl font-semibold text-gray-900 border-b-2 inline-block'>Pick a Service suited for you</h2>
                    <div className="mt-3">
                        <label htmlFor="services_category" className="block mb-2 text-sm font-medium text-gray-900"></label>
                        <div className="relative inline-block text-left w-full">
                        <select
                            value={servicesInfo.services_category}
                            onChange={handleCategoryChange}
                            required
                            className="block w-full bg-white border border-gray-300 rounded-lg shadow-sm p-2.5 text-sm focus:outline-none focus:ring-2 focus:ring-purple-800 focus:border-purple-800 hover:bg-purple-100 hover:border-purple-800 transition-all duration-300 ease-in-out"
                        >
                            <option value="" disabled>Select a category</option>
                            {categories.map((category) => (
                            <option
                                key={category.id}
                                value={category.category}
                                className="text-gray-900 dark:text-gray-300"
                            >
                                {category.category}
                            </option>
                            ))}
                        </select>
                        </div>

                    </div>

                    {services.length > 0 ? (
                        <div className="mt-6">
                            <label className="block mb-4 text-sm font-medium text-gray-900">Select Subcategories</label>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {services.map(service => (
                                <div key={service.id} className="flex items-center space-x-3 p-2 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                                <input
                                    type="checkbox"
                                    id={service.id}
                                    value={service.services}
                                    onChange={handleSubCategoryChange}
                                    className="w-4 h-4 text-purple-800 border-gray-300 rounded focus:ring-purple-800"
                                />
                                <label
                                    htmlFor={service.id}
                                    className="text-sm text-gray-700 font-medium cursor-pointer"
                                >
                                    {service.services}
                                </label>
                                </div>
                            ))}
                            </div>
                        </div>
                        ) : (
                            <div>
                                {servicesInfo.services_category!=="" && <p className='text-sm pt-2 text-gray-400'>No services available</p>}
                            
                            </div>
                        )}


                    <button
                        type="button"
                        onClick={handleSignUp}
                        className="w-full mt-6 bg-purple-800 text-white font-bold py-2 px-4 rounded hover:bg-purple-950 transition ease-in-out duration-300"
                    >
                        Save Provider Details
                    </button>
                </div>
            </div>
             {/* Right Side */}
             <div className="hidden sm:flex w-1/2 bg-gray-800 items-center justify-center p-8 pb-40 xl:pb-56">
          <div className="text-white text-center">
          <img src={VukaLogo} alt="Vuka Logo" className='w-auto h-auto 2xl:ml-5' />
            <h1 className="text-3xl lg:text-4xl xl:text-5xl lg:font-semibold lg:text-wrap  font-bold ">Sign Up and Start Earning.</h1>
            <p className="mt-4 lg:text-xl xl:text-2xl py-3 xl:py-8">Get your First Service Request.</p>
            
          </div>
        </div>
        </div>
    );
}
