import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, updateDoc, addDoc, Timestamp } from 'firebase/firestore';
import Rating from '@mui/material/Rating'; // MUI Rating component
import Swal from 'sweetalert2';
import Unavailable from '../assets/bg-image-background.png';


export default function ShowBookings() {
  const { userData } = useAuth();
  const db = getFirestore();
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [statusFilter, setStatusFilter] = useState('Pending');
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [providerInfo, setProviderInfo] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isClick, setIsClick] = useState(true);
  const [draggedDistance, setDraggedDistance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [registeredDate, setRegisteredDate] = useState('');
  const [ratingLoading, setRatingLoading] = useState(false);  
  const[error, setError] = useState(null);

  // Utility: Convert formatted date string to Firestore Timestamp
  const convertToTimestamp = (dateString) => {
    const [day, month, year, , time, ampm] = dateString.split(/[ ,]+/); 
    const date = new Date(`${month} ${day}, ${year} ${time} ${ampm}`);
    return isNaN(date.getTime()) ? null : Timestamp.fromDate(date);
  };

  // Utility: Get the formatted current date
  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours() % 12 || 12; 
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    setRegisteredDate(`${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`);
  };

  useEffect(() => {
    getFormattedDate();
  }, []);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setLoading(true);
        setError(null);

        if (!userData || !userData.id) {
          setError('User data is not available.');
          setLoading(false);
          return;
        }

        const bookingsRef = collection(db, 'serviceBookings');
        const q = query(bookingsRef, where('userId', '==', userData.id));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setLoading(false);
          return;
        }

        const fetchedBookings = await Promise.all(
          querySnapshot.docs.map(async (bookingDoc) => {
            const bookingData = { id: bookingDoc.id, ...bookingDoc.data() };

            if (bookingData.providerId) {
              const providerDocRef = doc(db, 'users', bookingData.providerId);
              const providerSnapshot = await getDoc(providerDocRef);
              bookingData.providerImage = providerSnapshot.exists()
                ? providerSnapshot.data().profile_imageUrl || providerSnapshot.data().profileImageUrl
                : '';
            }

            return bookingData;
          })
        );

        const sortedFetchedBookings = fetchedBookings.sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate));

        setBookings(sortedFetchedBookings);
        setFilteredBookings(sortedFetchedBookings.filter(b => b.status === statusFilter));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching bookings:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error Fetching Bookings',
          text: 'Failed to fetch bookings. Please try again later.',
          showConfirmButton: false,
          timer: 1500,
        });
        setError('Failed to fetch bookings.');
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userData, statusFilter, db]);

  useEffect(() => {
    if (selectedBooking && selectedBooking.providerId) {
      const fetchProviderInfo = async () => {
        try {
          const providerDoc = doc(db, 'users', selectedBooking.providerId);
          const providerSnapshot = await getDoc(providerDoc);

          if (providerSnapshot.exists()) {
            setProviderInfo(providerSnapshot.data());
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Provider not found.',
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error fetching provider information. Please try again later.',
          });
        }
      };
      fetchProviderInfo();
    }
  }, [selectedBooking, db]);

  const handleFilterChange = (status) => {
    if (isClick) {
      setStatusFilter(status);
      setFilteredBookings(bookings.filter(b => b.status === status));
    }
  };

  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
    setRating(booking.rating || 0);
    setComment('');
  };

  const handleRatingChange = (newRating) => setRating(newRating);

  const handleCommentChange = (e) => setComment(e.target.value);

  const submitReview = async () => {
    if (!selectedBooking || !providerInfo) return;

    try {
      setRatingLoading(true);

      const bookingDocRef = doc(db, 'serviceBookings', selectedBooking.id);
      const providerDocRef = doc(db, 'users', selectedBooking.providerId);
      const providerDoc = await getDoc(providerDocRef);

      if (providerDoc.exists()) {
        const providerData = providerDoc.data();
        const totalUserRated = providerData.totalUserRated || 0;
        const oldRating = providerData.rating || 0;

        const newTotalUserRated = totalUserRated + 1;
        const newRating = (((oldRating * totalUserRated) + rating) / newTotalUserRated).toFixed(1);

        await updateDoc(providerDocRef, { rating: newRating, totalUserRated: newTotalUserRated });
        await updateDoc(doc(db, `users/${selectedBooking.providerId}/Provider Details/Contact Info`), { provider_ratings: newRating });

        const reviewsRef = collection(db, `users/${userData.id}/Reviews`);
        await addDoc(reviewsRef, {
          clientName: userData.name,
          date: registeredDate,
          id: selectedBooking.bookingId,
          providerId: selectedBooking.providerId,
          providerName: providerInfo.name,
          rating,
          reply: 'reply',
          replyDate: '',
          review: comment,
          serviceImage: selectedBooking.image || '',
          userId: userData.id
        });

        await addDoc(collection(db, 'Ratings'), {
          providerId: selectedBooking.providerId,
          userId: userData.id,
          clientName: userData.name,
          providerName: providerInfo.name,
          bookingsId: selectedBooking.bookingId,
          image: selectedBooking.image || '',
          providerService: selectedBooking.providerService,
          serviceDate: convertToTimestamp(selectedBooking.serviceDate),
          rating,
          review: comment,
          regDate: registeredDate
        });

        await updateDoc(bookingDocRef, { jobRated: true });

        const updatedBookingDoc = await getDoc(bookingDocRef);
        if (updatedBookingDoc.exists()) {
          setSelectedBooking({ id: updatedBookingDoc.id, ...updatedBookingDoc.data() });
        }

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Review and rating submitted successfully!',
          showConfirmButton: false,
          timer: 1500,
        });

        setRating(0);
        setComment('');
      } else {
        Swal.fire({ icon: 'error', title: 'Error', text: 'Provider not found.' });
      }

      setRatingLoading(false);
    } catch (error) {
      console.log(error);
      Swal.fire({ icon: 'error', title: 'Error', text: 'Error submitting review and rating. Please try again later.' });
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
    setIsClick(true);
    setDraggedDistance(0);
  };

   // Handle touch events for scrolling
   const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
    setIsClick(true);
    setDraggedDistance(0);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 3;
    e.currentTarget.scrollLeft = scrollLeft - walk;
    setDraggedDistance(draggedDistance + Math.abs(x - startX));
    if (draggedDistance > 5) {
      setIsClick(false);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (draggedDistance > 5) {
      setIsClick(false);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 3;
    e.currentTarget.scrollLeft = scrollLeft - walk;
    setDraggedDistance(draggedDistance + Math.abs(x - startX));
    if (draggedDistance > 5) setIsClick(false);
  };

  const handleMouseUp = () => setIsDragging(false);
  const handleMouseLeave = () => setIsDragging(false);

  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen" role="status">
        <svg
          aria-hidden="true"
          className="text-gray-200 animate-spin dark:text-gray-600 fill-purple-800 w-14 h-14"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-500">Error: {error}</div>;
  }

  if (!bookings || bookings.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen relative z-10">
        <img src={Unavailable} alt="No Bookings Found" className="w-3/5 grayscale opacity-50 lg:w-2/5" />
        <p className="mt-5 text-lg text-gray-600">No bookings found.</p>
      </div>
    );
  }

  return (
    <div className='relative' >
       <div className='2xl:hidden bg-gray-800 h-12 absolute inset-0'></div>
      <h2 className="text-2xl font mb-6 text-center text-white relative z-10 2xl:text-black 2xl:font-semibold ">My Bookings</h2>

      <div className="mb-4 flex flex-row items-center justify-center relative z-10">
  <div
    className="flex overflow-x-hidden space-x-4 px-2"
    onMouseDown={handleMouseDown}
    onMouseLeave={handleMouseLeave}
    onMouseUp={handleMouseUp}
    onMouseMove={handleMouseMove}
    onTouchStart={handleTouchStart}  // Touch events
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
    style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
  >
    {['Pending', 'Accepted', 'Ongoing', 'Completed', 'Cancelled'].map((status) => {
      // Determine border and background color based on status
      let borderColor = '';
      let bgColor = '';
      switch (status) {
        case 'Pending':
          borderColor = 'border-black';
          bgColor = statusFilter === 'Pending' ? 'transition ease-in-out duration-200 bg-black text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        case 'Accepted':
          borderColor = 'border-blue-500';
          bgColor = statusFilter === 'Accepted' ? 'transition ease-in-out duration-200 bg-blue-500 text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        case 'Ongoing':
          borderColor = 'border-orange-500';
          bgColor = statusFilter === 'Ongoing' ? 'transition ease-in-out duration-200 bg-orange-500 text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        case 'Completed':
          borderColor = 'border-green-500';
          bgColor = statusFilter === 'Completed' ?  'transition ease-in-out duration-200 bg-green-500 text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        case 'Cancelled':
          borderColor = 'border-red-500';
          bgColor = statusFilter === 'Cancelled' ? 'transition ease-in-out duration-200 bg-red-500 text-white' : 'bg-white hover:bg-gray-100 text-black';
          break;
        default:
          break;
      }

      return (
        <button
          key={status}
          onClick={() => handleFilterChange(status)}
          draggable={false}
          className={`px-4 py-2 sm:mx-3 rounded-full border-2 ${borderColor} ${bgColor}`}
        >
          {status}
        </button>
      );
    })}
  </div>
</div>


      <ul className='divide-y divide-gray-100'>
        {filteredBookings.length > 0 ? (
          filteredBookings.map((booking) => (
            
            <li key={booking.bookingId} onClick={() => handleBookingClick(booking)} className="flex justify-between gap-x-4 py-5 cursor-pointer mx-5 hover:border-2 hover:bg-slate-100 hover:shadow-md rounded-full p-5 transtion ease-in-out duration-100 border-2 border-gray-100 mt-3">
              <div className='flex min-w-0 gap-x-4'>
              <img src={booking.providerImage} alt="Service" className="h-12 w-12 flex-none rounded-full bg-gray-50" />
              <div className="min-w-0 flex-auto">
              <p className="text-md font-semibold leading-6 text-gray-900">{booking.providerName}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">{booking.providerService || 'No service specified'}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">Booking Id:{booking.bookingId || 'No service specified'}</p>
            </div>
              </div>
              <div className="hidden shrink-0 md:flex md:flex-col md:items-end">
              <p className="text-sm leading-6 text-gray-900">{booking.status}</p>
              <p className="text-gray-700">
                <span className="font-semibold text-sm">Service Date: </span>
                {new Date(booking.serviceDate).toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
              })}
              </p>
              <p className="text-sm leading-6 text-gray-900">{booking.serviceLocation || 'Location not specified'}</p>
            </div>
            </li>
          ))
        ) : (
          <div className='flex flex-col items-center justify-center min-h-screen relative z-10'>
          <img src={Unavailable} alt='No Bookings' className='w-3/5 grayscale  opacity-50 lg:w-2/5' />
          <p className='text-3xl sm:text-6xl font-bold text-gray-800 opacity-50'>No Bookings Found</p>
          </div>
        )}
      </ul>

      {selectedBooking && providerInfo && (
       <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
        { ratingLoading && <div className=" fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
          <svg aria-hidden="true" class=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span className="sr-only">Loading...</span>
      </div>}
       <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-lg max-h-screen overflow-y-auto">
         {/* Close button (optional) */}
         {/* <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
           &times;
         </button> */}
   
         {/* Provider and Booking Image */}
         <div className="flex items-center mb-6">
           <img
             src={selectedBooking.image}
             alt="Booking Service"
             className="h-16 w-16 rounded-full border-2 border-gray-300 mr-4"
           />
           <div>
             <h3 className="text-xl font-bold text-gray-800">Booking Details</h3>
             <p className="text-gray-500">Booking ID: {selectedBooking.bookingId}</p>
           </div>
         </div>
   
         {/* Booking Information */}
         <div className="space-y-4">
           <p className="text-gray-700">
             <strong>Provider Service:</strong> {selectedBooking.providerService}
           </p>
           <p className="text-gray-700">
             <strong>Booking Date:</strong> {selectedBooking.bookingDate}
           </p>
           <p className="text-gray-700">
                <span className="font-semibold text-sm">Service Date: </span>
                {new Date(selectedBooking.serviceDate).toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
              })}
              </p>
           <p className="text-gray-700">
             <strong>Service Location:</strong> {selectedBooking.serviceLocation}
           </p>
   
           {/* Service Summary */}
           <h4 className="font-semibold text-lg text-gray-800 mt-4 border-b-2 border-gray-200 inline-block">Service Summary</h4>
           <p className="text-gray-700">
             <strong>Service:</strong> {selectedBooking.providerService}
           </p>
           <p className="text-gray-700">
             <strong>Description:</strong> {selectedBooking.serviceDescription}
           </p>
           <p className={`text-sm font-semibold mt-2 ${selectedBooking.status === 'Completed' ? 'text-green-600' : 'text-yellow-600'}`}>
             <strong>Status:</strong> {selectedBooking.status}
           </p>
         </div>
   
         {/* Provider Info */}
      <div className="border-t border-gray-200 mt-6 pt-4 ">
        <h4 className="font-semibold text-lg text-gray-800 mb-3 text-center">Provider Info</h4>
        <div className="flex flex-col items-center mb-4 justify-center">
          <div className='flex flex-col items-center jusfiy-center'>
            <div className='flex flex-col items-center justify-center'>
          <img
            src={providerInfo.profileImageUrl}
            alt="Provider"
            className="h-12 w-12 rounded-full border-2 border-gray-300  "
          />
          <p className="text-gray-700 font-medium ">{providerInfo.name}</p>
          <p className="text-gray-700 font-medium ">{providerInfo.email}</p>
          <p className="text-gray-700 font-medium">{providerInfo.phone}</p>
        </div>
           <div className="flex items-center mt-1 ">
                {[...Array(5)].map((_, i) => (
                  <svg
                    key={i}
                    className={`w-4 h-4 ${i < providerInfo.rating ? 'text-yellow-300' : 'text-gray-400'}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                  </svg>
                ))}
              </div>
          </div>
          <div>
            
            <div className="mt-4 space-y-2">
              {/* WhatsApp Button */}
              <a
                href={`https://wa.me/${providerInfo.phone}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-4 py-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-600 mr-2"
              >
                Chat on WhatsApp
              </a>

              {/* Email Button */}
              <a
                href={`mailto:${providerInfo.email}`}
                className="inline-block px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition ease-in-out duration-300"
              >
                Send an Email
              </a>
            </div>
          </div>
        </div>
      </div>

      {selectedBooking.status === 'Completed' && !selectedBooking.jobRated && (
            <div className="mt-6 p-6 bg-gray-100 rounded-lg shadow-md">
              <h4 className="font-semibold text-lg text-gray-800 mb-4">Leave a Review</h4>
              
              {/* Rating Component */}
              <div className="flex items-center space-x-4 mb-4">
                <Rating
                  name="rating"
                  value={rating}
                  onChange={(e, newValue) => handleRatingChange(newValue)}
                  className="text-yellow-400"
                />
                <span className="text-gray-600">{rating} out of 5</span>
              </div>

              {/* Review Textarea */}
              <textarea
                className="mt-2 w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                rows="4"
                placeholder="Write your review here..."
                value={comment}
                onChange={handleCommentChange}
              />

              {/* Submit Button */}
              <button
                onClick={submitReview}
                className="mt-4 px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition duration-300 ease-in-out"
              >
                Submit Review
              </button>
            </div>
          )}


            <button
              onClick={() => setSelectedBooking(null)}
              className="mt-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700 transition ease-in-out duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
